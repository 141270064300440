var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "content table-components" },
      [
        _c(
          "el-table",
          {
            attrs: {
              "header-cell-style": { textAlign: "center" },
              "cell-style": { textAlign: "center" },
              data: _vm.data,
              border: ""
            },
            on: {
              "sort-change": _vm.sortChange,
              "selection-change": _vm.handleSelectionChange
            }
          },
          [
            _vm.showSelection
              ? _c("el-table-column", {
                  attrs: { type: "selection", width: "55" }
                })
              : _vm._e(),
            _vm.showIndex
              ? _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" }
                })
              : _vm._e(),
            _vm._l(_vm.columns, function(item, index) {
              return [
                item.children
                  ? [
                      _c(
                        "el-table-column",
                        { key: index, attrs: { label: item.label } },
                        _vm._l(item.children, function(childItem, childIndex) {
                          return _c("column-item", {
                            key: childIndex,
                            attrs: { item: childItem, index: childIndex }
                          })
                        }),
                        1
                      )
                    ]
                  : _c("column-item", {
                      key: index,
                      attrs: { item: item, index: index }
                    })
              ]
            }),
            _vm._t("operate")
          ],
          2
        ),
        _vm.pageSize
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", margin: "20px 0 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": _vm.pageSize,
                    "current-page": _vm.pageNum,
                    total: _vm.total
                  },
                  on: { "current-change": _vm.handleCurrentChange }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }