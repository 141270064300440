<template>
  <div>
    <el-table-column
      v-if="
        !item.slotName &&
        item.prop != 'index' &&
        item.type != 'img' &&
        item.type != 'qrCode'
      "
      :label="item.label"
      :key="index"
      :prop="item.prop"
      :width="item.width ? item.width : '200'"
      :fixed="item.fixed"
      :formatter="item.formatter"
      :sortable="item.sortable ? item.sortable : false"
      :show-overflow-tooltip="
        item.showOverflowTooltip ? item.showOverflowTooltip : true
      "
    >
    </el-table-column>
    <el-table-column
      v-if="item.type == 'img'"
      :label="item.label"
      :key="index"
      :prop="item.prop"
      :width="item.width ? item.width : 100"
      :fixed="item.fixed"
    >
      <template slot-scope="scope">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-image
            v-if="scope.row[item.prop]"
            style="max-width: 100px; height: 40px"
            :src="scope.row[item.prop]"
            fit="contain"
            :preview-src-list="[scope.row[item.prop]]"
          >
          </el-image>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      v-if="item.type == 'qrCode'"
      :label="item.label"
      :key="index"
      :prop="item.prop"
      :width="item.width ? item.width : 100"
      :fixed="item.fixed"
    >
      <template slot-scope="scope">
        <div
          v-if="scope.row[item.prop]"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="qr-container" @click="toggleQrZoom">
            <div v-if="item.url">
              <vue-qr
                style="display: inline-block"
                :text="item.url + scope.row[item.prop]"
                :size="40"
              ></vue-qr>
            </div>
            <div v-else>
              <vue-qr
                style="display: inline-block"
                :text="scope.row[item.prop]"
                :size="40"
              ></vue-qr>
            </div>
          </div>

          <div v-if="isQrZoomed" class="zoomed-qr-container">
            <div>
              <i @click="toggleQrZoom" class="el-icon-circle-close"></i>
            </div>
            <div v-if="item.url">
              <vue-qr
                style="display: inline-block"
                :text="item.url + scope.row[item.prop]"
                :size="250"
              ></vue-qr>
            </div>
            <div v-else>
              <vue-qr
                style="display: inline-block"
                :text="scope.row[item.prop]"
                :size="250"
              ></vue-qr>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>

    <slot :name="item.slotName" v-if="item.slotName"></slot>
  </div>
</template>
<script>
export default {
  props: {
    item: {},
    index: {}
  }
}
</script>