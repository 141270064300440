var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative", "padding-top": "20px" } },
    [
      _c("div", {
        staticClass: "echart",
        style: { width: "100%", height: "500px" },
        attrs: { id: _vm.id }
      }),
      _c(
        "div",
        { staticClass: "icon-open" },
        [
          _c("el-switch", {
            attrs: { "active-text": "显示数值" },
            on: { change: _vm.handleSwitch },
            model: {
              value: _vm.ifShowLabel,
              callback: function($$v) {
                _vm.ifShowLabel = $$v
              },
              expression: "ifShowLabel"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }