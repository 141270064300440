const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  menus: state => state.user.menus,
  info: state => state.user.info,
  socket: state => state.user.socket,
  sockettime: state => state.user.sockettime,
  messagetime: state => state.user.messagetime,
}
export default getters
