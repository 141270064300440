var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pages" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.resDetails.addFriendNum))
          ]),
          _c("div", { staticClass: "label" }, [_vm._v("昨日新增好友数")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.resDetails.delFriendNum))
          ]),
          _c("div", { staticClass: "label" }, [_vm._v("昨日删除好友数")])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.resDetails.friendTotal))
          ]),
          _c("div", { staticClass: "label" }, [_vm._v("昨日好友数总数量")])
        ]),
        _c(
          "div",
          {
            staticClass: "item able-click",
            on: {
              click: function($event) {
                _vm.visibleDialog = true
              }
            }
          },
          [
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.resDetails.averageFriendNum))
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("昨日删除好友平均数")])
          ]
        ),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.resDetails.assetsTotal))
          ]),
          _c("div", { staticClass: "label" }, [_vm._v("资产总数")])
        ]),
        _c(
          "div",
          {
            staticClass: "item able-click",
            on: {
              click: function($event) {
                return _vm.turnPage("/assetsList/normal")
              }
            }
          },
          [
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.resDetails.assetsNormalTotal))
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("正常资产数")])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "item able-click",
            on: {
              click: function($event) {
                return _vm.turnPage("/assetsList/abnormal")
              }
            }
          },
          [
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.resDetails.assetsExceptionalTotal))
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("异常资产数")])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "item able-click",
            on: {
              click: function($event) {
                return _vm.turnPage("/assetsList/disused")
              }
            }
          },
          [
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.resDetails.assetsScrapTotal))
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("报废资产数")])
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "昨日删除好友异常账号",
            visible: _vm.visibleDialog,
            width: "60%",
            "close-on-click-modal": true
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "margin-bottom": "10px"
              }
            },
            [
              _vm.$store.state.common.hasPermission.includes("导出")
                ? _c("export-excel", {
                    attrs: {
                      requestParams: _vm.dialogQuery,
                      action: "/manage/wechat/data/qryOverview",
                      columns: _vm.columns,
                      filename: "昨日删除好友异常账号",
                      customFields: "deleteFriendList"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("table-components", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: {
              showIndex: false,
              columns: _vm.columns,
              tableData: _vm.resDetails.deleteFriendList,
              total: _vm.total
            },
            on: { "get-list": _vm.getList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }