<template>
  <div class="abnormal">
    <div class="head">
      <el-form :model="queryForm">
        <el-form-item label="微信号：">
          <el-input
            v-model="queryForm.wechatNum"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号：">
          <el-input
            v-model="queryForm.bindPhone"
            oninput="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="使用人：">
          <el-select
            v-model="userIds"
            multiple
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :label="item.personRealName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信号属性：">
          <el-select
            v-model="attributeIds"
            multiple
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in attrPages"
              :label="item.attributeName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button @click="tableSearch" type="primary">查询</el-button>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="queryForm"
          action="/manage/wechat/assets/qryPage"
          :columns="columns"
          filename="资产列表"
        ></export-excel>
      </el-form>
    </div>
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <template v-for="(item, index) in columns">
          <el-table-column
            v-if="item.prop != 'loginPassword'"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :formatter="item.formatter"
          ></el-table-column>
        </template>
        <el-table-column label="操作" width="400px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="$store.state.common.hasPermission.includes('报废')"
              @click="
                scrapReasonVisible = true
                dataId = scope.row.id
              "
              >报废
            </el-button>
            <el-button
              size="mini"
              v-if="$store.state.common.hasPermission.includes('恢复正常')"
              @click="
                planReasonVisible = true
                dataId = scope.row.id
              "
              >恢复正常
            </el-button>
            <el-button
              size="mini"
              v-if="$store.state.common.hasPermission.includes('变更')"
              @click="
                changeVisible = true
                dataId = scope.row.id
              "
              >变更
            </el-button>
            <el-button
              size="mini"
              v-if="$store.state.common.hasPermission.includes('编辑')"
              @click="
                dialogVisible = true
                InfoTitle = '编辑'
                readOnly = true
                queryData(scope.row)
              "
              >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div style="text-align: center; margin: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--    删除-->
    <el-dialog title="温馨提示" :visible.sync="deleteObject" width="25%">
      <div>确定要删除该微信号吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteObject = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeObject()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  新增编辑-->
    <el-dialog
      class="abnormal-dialog"
      :title="InfoTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form :model="dialogForm" ref="dialogForm" :rules="dialogFormRules">
        <el-form-item label="微信号：" prop="wechatNum">
          <el-input v-model="dialogForm.wechatNum"> </el-input>
        </el-form-item>
        <el-form-item label="微信好友数：" prop="friendNum">
          <el-input :readonly="readOnly" v-model="dialogForm.friendNum">
          </el-input>
        </el-form-item>
        <el-form-item label="使用人：" prop="userId">
          <el-select
            v-model="dialogForm.userId"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :label="item.personRealName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信昵称：" prop="nickName">
          <el-input v-model="dialogForm.nickName"> </el-input>
        </el-form-item>
        <el-form-item label="登录方式：" prop="loginMethod">
          <el-input v-model="dialogForm.loginMethod"> </el-input>
        </el-form-item>
        <el-form-item label="登录密码：" prop="loginPassword">
          <el-input v-model="dialogForm.loginPassword"> </el-input>
        </el-form-item>
        <el-form-item label="实名认证：" prop="realName">
          <el-input v-model="dialogForm.realName"> </el-input>
        </el-form-item>
        <el-form-item label="绑定手机号：" prop="bindPhone">
          <el-input
            oninput="this.value = this.value.replace(/[^\d.]/g,'');"
            v-model="dialogForm.bindPhone"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号使用人：" prop="phoneUsing">
          <el-select
            v-model="dialogForm.phoneUsing"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :label="item.personRealName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信号属性：" prop="attributeId">
          <el-select
            v-model="dialogForm.attributeId"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in attrPages"
              :label="item.attributeName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行：">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="dialogForm.bankName"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="银行卡号：">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="dialogForm.bankNo"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="支付密码：">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="dialogForm.bankPass"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="可修改微信号时间：">
          <el-date-picker
            v-model="dialogForm.editWechatDate"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注：" prop="remarks">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="dialogForm.remarks"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDialog()">确 定</el-button>
      </span>
    </el-dialog>
    <!--    账号报废标记-->
    <el-dialog
      title="账号报废标记"
      :visible.sync="scrapReasonVisible"
      width="25%"
    >
      <div style="display: flex">
        <div>报废原因：</div>
        <div style="margin-left: 10px; width: 70%">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="scrapReason"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scrapReasonVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="scrapReasonObj()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--    账号恢复正常-->
    <el-dialog
      title="账号恢复正常"
      :visible.sync="planReasonVisible"
      width="20%"
    >
      <div>确认该账号已恢复正常</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="planReasonVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="planReasonVisibleObj()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--    变更使用人-->
    <el-dialog title="变更使用人" :visible.sync="changeVisible" width="25%">
      <div style="display: flex; flex-wrap: wrap">
        <div style="width: 100px">变更后使用人：</div>
        <div style="margin-left: 10px; width: 70%">
          <el-select
            v-model="changeUserId"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :label="item.personRealName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div style="width: 100px; margin-top: 20px">变更时间：</div>
        <div style="width: 70%; margin-top: 16px">
          <el-date-picker
            style="width: 200px; margin-left: 10px"
            v-model="changeTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="changeObject()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deleteInfo,
  qryPage,
  saveInfo,
  scrapObj,
  recoveryObj,
  changeObj
} from '@/api/assetsList'
import { attrPage, ExceptionalPage } from '@/api/labelManagement'
import { queryList } from '@/api/organization'
let exceptionalIds = []
let columns = [
  {
    label: '微信号',
    prop: 'wechatNum'
  },
  {
    label: '微信好友数',
    prop: 'friendNum'
  },
  {
    label: '微信状态',
    prop: 'wechatStatusName'
  },
  {
    label: '使用人',
    prop: 'userName'
  },
  {
    label: '所在部门',
    prop: 'deptName'
  },
  {
    label: '微信昵称',
    prop: 'nickName'
  },
  {
    label: '登录方式',
    prop: 'loginMethod'
  },
  {
    label: '登录密码',
    prop: 'loginPassword'
  },
  {
    label: '实名认证信息',
    prop: 'realName'
  },
  {
    label: '开户行',
    prop: 'bankName'
  },
  {
    label: '银行卡号',
    prop: 'bankNo'
  },
  {
    label: '可修改微信号时间',
    prop: 'editWechatDate'
  },
  {
    label: '绑定手机号',
    prop: 'bindPhone'
  },
  {
    label: '手机号使用人',
    prop: 'phoneUsingName'
  },
  {
    label: '微信号属性',
    prop: 'attributeName'
  },
  {
    label: '异常状态',
    prop: 'exceptionalName'
  },
  {
    label: '异常时间',
    prop: 'exceptionalTime'
  },
  {
    label: '拟解决方案',
    prop: 'planReason'
  },
  {
    label: '拟解决时间',
    prop: 'planTime'
  },
  {
    label: '操作时间',
    prop: 'updatedTime'
  }
]
export default {
  data() {
    return {
      exceptionalIds: [],
      changeTime: '',
      changeUserId: '',
      changeVisible: false,
      planReasonVisible: false,
      planReason: {
        exceptionalTime: '',
        exceptionalId: '',
        planTime: '',
        planReason: ''
      },
      scrapReasonVisible: false,
      scrapReason: '',
      readOnly: false,
      userList: [],
      exceptionalList: [],
      attrPages: [],
      userIds: [],
      attributeIds: [],
      columns: columns,
      dialogForm: {
        wechatNum: '',
        friendNum: '',
        userId: '',
        nickName: '',
        loginMethod: '',
        loginPassword: '',
        realName: '',
        bindPhone: '',
        phoneUsing: '',
        attributeId: '',
        remarks: ''
      },
      dialogVisible: false,
      InfoTitle: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        wechatNum: '',
        bindPhone: '',
        userIds: '',
        attributeIds: ''
      },
      total: 0,
      tableData: [],
      abnormalDialog: false,
      abnormalForm: {},
      deleteObject: false,
      dataId: null,
      loading: false,
      dialogFormRules: {
        wechatNum: [
          { required: true, message: '请输入微信号', trigger: ['blur'] }
        ],
        friendNum: [
          { required: true, message: '请输入微信好友数', trigger: ['blur'] }
        ],
        userId: [
          { required: true, message: '请选择使用人', trigger: ['blur'] }
        ],
        nickName: [
          { required: true, message: '请输入微信昵称', trigger: ['blur'] }
        ],
        loginMethod: [
          { required: true, message: '请输入登录方式', trigger: ['blur'] }
        ],
        loginPassword: [
          { required: true, message: '请输入登录密码', trigger: ['blur'] }
        ],
        realName: [
          { required: true, message: '请输入实名认证', trigger: ['blur'] }
        ],
        bindPhone: [
          { required: true, message: '请输入绑定手机号', trigger: ['blur'] }
        ],
        phoneUsing: [
          { required: true, message: '请选择手机号使用人', trigger: ['blur'] }
        ],
        attributeId: [
          { required: true, message: '请选择微信号属性', trigger: ['blur'] }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() //如果没有后面的-8.64e7就是不可以选择今天的
        }
      }
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'assetsListAbnormal')
    // 微信号属性
    attrPage({
      pageNum: 1,
      deletedFlag: 2,
      pageSize: 9999999
    }).then((data) => {
      this.attrPages = data.data.list
    })
    // 用户列表
    queryList({
      pageNum: 1,
      pageSize: 9999999
    }).then((data) => {
      this.userList = data.data.list
    })
    // 异常状态列表
    ExceptionalPage({
      pageNum: 1,
      pageSize: 9999999
    }).then((data) => {
      this.exceptionalList = data.data.list
      exceptionalIds = this.exceptionalList
    })
    this.doGetList()
  },
  methods: {
    // 变更
    changeObject() {
      if (!this.changeUserId) {
        this.$message.error('请选择变更使用人')
        return
      }
      if (!this.changeTime) {
        this.$message.error('请选择变更时间')
        return
      }
      this.loading = true
      changeObj({
        id: this.dataId,
        userId: this.changeUserId,
        changeTime: this.changeTime
      }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dataId = ''
          this.changeVisible = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    // 异常
    planReasonVisibleObj() {
      this.loading = true
      recoveryObj({ id: this.dataId }).then((res) => {
        this.loading = false
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.planReasonVisible = false
          this.doGetList()
          this.dataId = ''
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //报废
    scrapReasonObj() {
      if (!this.scrapReason) {
        this.$message.error('请填写报废原因')
        return
      }
      this.loading = true
      scrapObj({ id: this.dataId, scrapReason: this.scrapReason }).then(
        (res) => {
          if (res.status == 200) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dataId = ''
            this.scrapReasonVisible = false
            this.doGetList()
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }
      )
    },
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      this.queryForm.wechatStatus = 1
      this.queryForm.userIds = this.userIds.join(',')
      this.queryForm.attributeIds = this.attributeIds.join(',')
      qryPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    },
    saveDialog() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.loading = true
          saveInfo(this.dialogForm).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.dialogVisible = false
              this.doGetList()
              this.fromClear()
            } else {
              this.$message.error(res.message)
            }
            this.loading = false
          })
        }
      })
    },
    fromClear() {
      this.dialogForm = {
        wechatNum: '',
        friendNum: '',
        userId: '',
        nickName: '',
        loginMethod: '',
        loginPassword: '',
        realName: '',
        bindPhone: '',
        phoneUsing: '',
        attributeId: '',
        remarks: ''
      }
    },
    // 编辑回显
    queryData(row) {
      this.dialogForm = Object.assign({}, row)
    },
    removeObject() {
      this.loading = true
      deleteInfo({ id: this.dataId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dataId = ''
          this.deleteObject = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
.abnormal {
  .el-form-item {
    margin-right: 20px;
  }

  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }

  .el-form-item__content {
    margin-left: 0;
    width: 200px;
  }
}
</style>
<style lang="scss" scoped>
.head,
.content {
  overflow: hidden;
  margin: 30px;
}

::v-deep .table-input .el-input__inner {
  text-align: center;
}

::v-deep .abnormal-dialog {
  .el-form-item__label {
    width: 120px;
  }
  .el-select {
    width: 200px;
  }

  .el-input__inner {
    width: 200px;
  }

  .el-textarea {
    width: 540px;
  }
}
</style>
