<template>
  <div class="recover">
    <div class="head">
      <list-query
        :show-filter="false"
        :show-other="false"
        @search="tableSearch"
        :show-add="false"
      >
        <template slot="default">
          <el-form inline :model="queryForm">
            <el-form-item >
              <el-input
                v-model="queryForm.wechatNum"
                placeholder="请输入恢复微信号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 400px">
              <el-date-picker
                v-model="createTime"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>

          </el-form>
        </template>
      <template slot="default-btn">
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="queryForm"
          action="/manage/assets/track/recoveryPage"
          :columns="columns"
          filename="恢复记录"
        ></export-excel>
      </template>
      </list-query>
    </div>
    <div class="content">
      <table-components
        :columns="columns"
        :total="total"
        :pageNum="queryForm.pageNum"
        :pageSize="queryForm.pageSize"
        :data="tableData"
        v-loading="loading"
        @get-list="handleCurrentChange"
      ></table-components>
    </div>
  </div>
</template>
<script>
import { recoveryPage } from '@/api/operateRecords'

let columns = [
  {
    label: '恢复微信号',
    prop: 'wechatNum',
    width:'auto'
  },
  {
    label: '恢复时间',
    prop: 'createdTime',
    width:'auto'
  },
  {
    label: '操作人',
    prop: 'createdByName',
    width:'auto'
  }
]
export default {
  data() {
    return {
      columns: columns,
      createTime: [],
      dialogForm: {
        wechatNum: ''
      },
      dialogVisible: false,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        recoveryStart: '',
        recoveryEnd: '',
        wechatNum: ''
      },
      total: 0,
      tableData: [],
      loading: false
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'operateRecordsRecover')
    const end = new Date().Format('yyyy-MM-dd 23:59:59')
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    this.createTime = [start.Format('yyyy-MM-dd 00:00:00'), end]
    this.queryForm.recoveryStart = start.Format('yyyy-MM-dd 00:00:00')
    this.queryForm.recoveryEnd = end
    this.doGetList()
  },
  methods: {
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      if (this.createTime) {
        this.queryForm.recoveryStart = this.createTime[0]
        this.queryForm.recoveryEnd = this.createTime[1]
      } else {
        this.queryForm.recoveryStart = ''
        this.queryForm.recoveryEnd = ''
      }
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      recoveryPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    }
  }
}
</script>

