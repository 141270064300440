import request from '@/utils/request'

// 微信资产分页
export function qryPage(data) {
  return request({
    url: '/manage/wechat/assets/qryPage',
    method: 'post',
    data
  })
}
// 微信资产信息保存
export function saveInfo(data) {
  return request({
    url: '/manage/wechat/assets/saveInfo',
    method: 'post',
    data
  })
}
// 微信资产报废
export function scrapObj(data) {
  return request({
    url: '/manage/wechat/assets/scrapObj',
    method: 'post',
    data
  })
}
// 微信资产异常
export function exceptionalObj(data) {
  return request({
    url: '/manage/wechat/assets/exceptionalObj',
    method: 'post',
    data
  })
}
// 微信资产变更
export function changeObj(data) {
  return request({
    url: '/manage/wechat/assets/changeObj',
    method: 'post',
    data
  })
}
// 微信资产删除
export function deleteInfo(data) {
  return request({
    url: '/manage/wechat/assets/deleteInfo',
    method: 'post',
    data
  })
}
// 微信资产恢复
export function recoveryObj(data) {
  return request({
    url: '/manage/wechat/assets/recoveryObj',
    method: 'post',
    data
  })
}
// 根据所选课程顾问查询资产
export function queryList(data) {
  return request({
    url: '/manage/wechat/assets/queryList',
    method: 'post',
    data
  })
}
