import Cookies from 'js-cookie'
import { queryMajorTreeAll, queryPersonDeptList, queryChannelList, operateListQuery, payModeQuery, labelPage } from '@/api/SystemManager'
import {
  queryLabelList,
} from '@/api/cloudClassCenter'
import { queryConsultantList, queryAllConsultant } from "@/api/userManagement"
import { findRoleMenuButton } from '@/api/user'
import { getDeptTree } from "@/api/organization";
import { ringCauseQuery } from '@/api/dailyRecord'
import { followStageQuery, userStatusQuery, getCustomLabelTree, linkageChannel } from '@/api/crm'
import { coursePageQuery } from '@/api/cloudClassCenter'
const state = {
  majorlist: [], // 学科分类
  labelList: [], // 课程标签
  consultantList: [], // 自己及自己下级课程顾问列表
  allConsultantList: [], // 查询所有课程顾问（不区分上下级关系）
  personDeptList: [], // 部门及其下属
  hasPermission: [], // 按钮权限
  channelList: [], // 运营渠道
  personLabelList: [], // 教师标签
  orderStatusList: [
    {
      label: '待砍成',
      value: '0'
    },
    {
      label: '待拼成',
      value: '1'
    },
    {
      label: '待审核',
      value: '2'
    },
    {
      label: '审核驳回',
      value: '3'
    },
    {
      label: '待付尾款',
      value: '4'
    },
    {
      label: '待付款',
      value: '10'
    },
    {
      label: '待发货',
      value: '20'
    },
    {
      label: '待收货',
      value: '30'
    },
    {
      label: '已收货',
      value: '40'
    },
    {
      label: '已退款',
      value: '50'
    },
    {
      label: '超时未支付关闭',
      value: '60'
    },
    {
      label: '超时未砍成',
      value: '61'
    },
    {
      label: '已取消',
      value: '62'
    },
    {
      label: '完成',
      value: '70'
    }
  ], // 订单状态
  payModeList: [],// 支付方式
  deptList: [], // 部门列表
  operateList: [], // 运营人员列表（除运营角色外查询所有运营人员，运营人员选项查询自己和自己的下级）
  contentType: [{  // 内容类型
    label: '问答',
    value: 0,
  }, {
    label: '资料',
    value: 1
  }, {
    label: '动态',
    value: 2
  }, {
    label: '云课',
    value: 3
  }, {
    label: '知识库',
    value: 4
  }],
  ringCauseList: [], // 通话呼叫状态列表
  followStageList: [], // 跟进阶段
  userStatusList: [], // 用户状态
  customLabelTree: [], // 自定义标签树形结构 
  coursePackageList: [], // 课程、课程包下拉
  sourceList: [], // 学生来源渠道

}

const mutations = {
  allConsultantList: (state, allConsultantList) => {
    state.allConsultantList = allConsultantList
  },
  sourceList: (state, sourceList) => {
    state.sourceList = sourceList
  },
  coursePackageList: (state, coursePackageList) => {
    state.coursePackageList = coursePackageList
  },
  customLabelTree: (state, customLabelTree) => {
    state.customLabelTree = customLabelTree
  },
  followStageList: (state, followStageList) => {
    state.followStageList = followStageList
  },
  userStatusList: (state, userStatusList) => {
    state.userStatusList = userStatusList
  },
  ringCauseList: (state, ringCauseList) => {
    state.ringCauseList = ringCauseList
  },
  personLabelList: (state, personLabelList) => {
    state.personLabelList = personLabelList
  },
  SET_PAYMODELIST: (state, payModeList) => {
    state.payModeList = payModeList
  },
  SET_OPERATElIST: (state, operateList) => {
    state.operateList = operateList
  },
  SET_CHANNELLIST: (state, channelList) => {
    state.channelList = channelList
  },
  SET_HASPERMISSION: (state, hasPermission) => {
    state.hasPermission = hasPermission
  },
  SET_MAJORLIST: (state, majorlist) => {
    state.majorlist = majorlist
  },
  SET_LABELLIST: (state, labelList) => {
    state.labelList = labelList
  },
  SET_CONSULTANTLIST: (state, consultantList) => {
    state.consultantList = consultantList
  },
  SET_PERSONDEPTLIST: (state, personDeptList) => {
    state.personDeptList = personDeptList
  },
  SET_DEPTLIST: (state, deptList) => {
    state.deptList = deptList
  }
}

const actions = {
  // 查询所有课程顾问
  getAllConsultantList({ commit }) {
    queryAllConsultant().then(res => {
      if (res.status == 200) {
        commit('allConsultantList', res.data)
      }
    })
  },
  // 获取学生来源渠道
  getSourceList({ commit }, paramData) {
    const params = {
      parentId: paramData ? paramData.parentId : '',
      type: paramData ? paramData.type : null  // 1-有效  2|null 全部
    }
    linkageChannel(params).then(res => {
      if (res.status == 200) {
        commit('sourceList', res.data)
      }
    })
  },

  // 所有课程、课程包下拉
  getCoursePackageList({ commit }, shelfStatus) {
    let params = {
      shelfStatus: 1,
      pageNum: 1,
      pageSize: 99999999
    }
    if (shelfStatus) {
      params.shelfStatus = shelfStatus
    }
    coursePageQuery(params).then(res => {
      if (res.status == 200) {
        commit('coursePackageList', res.data.list)
      }
    })
  },
  // 自定义标签树形结构
  getCustomLabelTreeList({ commit }) {
    getCustomLabelTree().then(res => {
      if (res.status == 200) {
        commit('customLabelTree', res.data)
      }
    })
  },
  //跟进阶段
  getFollowStageList({ commit }) {
    followStageQuery().then(res => {
      if (res.status == 200) {
        commit('followStageList', res.data)
      }
    })
  },
  //用户状态
  getUserStatusList({ commit }) {
    userStatusQuery().then(res => {
      if (res.status == 200) {
        commit('userStatusList', res.data)
      }
    })
  },
  // 通话呼叫状态
  getRingCauseList({ commit }) {
    ringCauseQuery().then(res => {
      if (res.status == 200) {
        commit('ringCauseList', res.data)
      }
    })
  },
  getPersonLabelList({ commit }, labelName) {
    const params = {
      pageNum: 1,
      pageSize: 999999,
      labelName: labelName ? labelName : ''
    }
    labelPage(params).then(res => {
      if (res.status == 200) {
        commit('personLabelList', res.data.list)
      }
    })
  },
  // 支付方式
  getPayModeList({ commit }) {
    payModeQuery().then(res => {
      if (res.status == 200) {
        commit('SET_PAYMODELIST', res.data)
      }
    })
  },
  // 运营人员list
  getOperateList({ commit }, personName, personPhone) {
    const params = {
      personName: personName,
      personPhone: personPhone
    }
    operateListQuery(params).then(res => {
      if (res.status == 200) {
        commit('SET_OPERATElIST', res.data)
      }
    })
  },
  // 所有部门列表
  getDeptList({ commit }) {
    getDeptTree().then(res => {
      commit('SET_DEPTLIST', res.data[0]['childList'])
    })
  },
  // 运营渠道list
  getChannelList({ commit }) {
    const params = {
      pageNum: 1,
      pageSize: 9999
    }
    queryChannelList(params).then(res => {
      if (res.status == 200) {
        commit('SET_CHANNELLIST', res.data.list)
      }
    })
  },
  // 按钮权限
  findRoleMenuButtonFn({ commit }, name) {
    findRoleMenuButton({ name: name }).then((res) => {
      commit('SET_HASPERMISSION', res.data)
    })
  },
  // 部门及其下属
  getPersonDeptList({ commit }) {
    queryPersonDeptList().then(res => {
      if (res.status == 200) {
        commit('SET_PERSONDEPTLIST', res.data)
      }
    })

  },
  // 拉取学科分类类别
  getMajorlist({ commit }) {
    queryMajorTreeAll().then((res) => {
      if (res.status == 200) {
        commit('SET_MAJORLIST', res.data.data)
      }
    })
  },
  // 拉取课程标签
  getLabelList({ commit }) {
    queryLabelList().then((res) => {
      if (res.status == 200) {
        commit('SET_LABELLIST', res.data)
      }
    })
  },
  // 拉取自己及自己下级的课程顾问  deptIds存在时，与部门联动
  getConsultantList({ commit }, deptIds, userName) {
    const params = {
      userName: userName ? userName : '',
      deptIds: deptIds ? deptIds : ''
    }
    queryConsultantList(params).then(res => {
      commit('SET_CONSULTANTLIST', res.data)
    })
  }
}

export default {
  namespaced: false,
  state,
  mutations,
  actions
}
