import request from "@/utils/request";

//异常记录分页
export function exceptionalPage(data) {
  return request({
    url: '/manage/assets/track/exceptionalPage',
    method: 'post',
    data
  })
}

//恢复记录分页
export function recoveryPage(data) {
  return request({
    url: '/manage/assets/track/recoveryPage',
    method: 'post',
    data
  })
}

//变更记录分页
export function changePage(data) {
  return request({
    url: '/manage/assets/track/changePage',
    method: 'post',
    data
  })
}

//报废记录分页
export function scrapPage(data) {
  return request({
    url: '/manage/assets/track/scrapPage',
    method: 'post',
    data
  })
}
