import request from '@/utils/request'
// 有效渠道列表
export function queryChannelList() {
  return request({
    url: '/manage/wechat/data/queryChannelList',
    method: 'post',
  })
}
// 输入录入查询
export function queryList(data) {
  return request({
    url: '/manage/wechat/data/queryList',
    method: 'post',
    data
  })
}
// 输入录入保存
export function saveAddData(data) {
  return request({
    url: '/manage/wechat/data/saveAddData',
    method: 'post',
    data
  })
}
// 数据总览
export function qryOverview(data) {
  return request({
    url: '/manage/wechat/data/qryOverview',
    method: 'post',
    data
  })
}
// 资产异常标记
export function exceptionalObj(data) {
  return request({
    url: '/manage/wechat/assets/exceptionalObj',
    method: 'post',
    data
  })
}
// 资产异常状态
export function exceptionalPage(data) {
  return request({
    url: '/manage/assets/status/exceptionalPage',
    method: 'post',
    data
  })
}
// 资产恢复
export function recoveryObj(data) {
  return request({
    url: '/manage/wechat/assets/recoveryObj',
    method: 'post',
    data
  })
}
// 资产渠道数据导入
export function importAssets(data) {
  return request({
    url: '/manage/wechat/data/importAssets',
    method: 'post',
    data
  })
}