<template>
  <div>
    <div class="item">
      <div
        class="label"
        v-if="label"
        :style="labelWidth ? `min-width:${labelWidth}` : 'min-width:130px;'"
      >
        <span :class="required ? 'is-required' : ''">
          {{ label }}
          <template v-if="label && label != ' '">：</template>
        </span>
      </div>
      <div class="item-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'labelWidth', 'required'],
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  margin-bottom: 20px;
  line-height: 40px;
  margin-right: 20px;
  .label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    padding: 0 12px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // min-width: 115px;
    font-weight: bold;
    .is-required::before {
      content: '*';
      color: red;
      position: relative;
      left: -2px;
      top: 0;
    }
  }
}
</style>