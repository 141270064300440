<template>
  <div>
    <div class="head">
      <list-query
        :show-other="false"
        :show-filter="false"
        @search="tableSearch"
        @add="
          dialogVisible = true
          InfoTitle = '新增'
          fromClear()
        "
      >
        <el-form :model="queryForm" inline>
          <el-form-item>
            <el-input
              v-model="queryForm.exceptionalName"
              placeholder="请输入异常状态名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </list-query>
    </div>
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="exceptionalName"
          label="异常状态名称"
          fixed
        ></el-table-column>

        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="$store.state.common.hasPermission.includes('编辑')"
              @click="
                dialogVisible = true
                InfoTitle = '编辑'
                queryData(scope.row)
              "
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="primary"
              v-if="$store.state.common.hasPermission.includes('删除')"
              @click="handleDelete(scope.$index, scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div style="text-align: center; margin: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="温馨提示" :visible.sync="deleteObject" width="25%">
      <div>确定要删除该异常状态吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteObject = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeObject()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="abnormal-dialog"
      :title="InfoTitle"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <el-form
        :model="dialogForm"
        inline
        ref="dialogForm"
        :rules="dialogFormRules"
      >
        <el-form-item label="异常状态名称：" prop="exceptionalName">
          <el-input v-model="dialogForm.exceptionalName"> </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDialog()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  ExceptionalPage,
  deleteExceptional,
  saveExceptional
} from '@/api/labelManagement'

export default {
  data() {
    return {
      dialogForm: {
        exceptionalName: ''
      },
      dialogVisible: false,
      InfoTitle: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        exceptionalName: ''
      },
      total: 0,
      tableData: [],
      abnormalDialog: false,
      abnormalForm: {},
      deleteObject: false,
      dataId: null,
      loading: false,
      dialogFormRules: {
        exceptionalName: [
          { required: true, message: '请输入异常状态名称', trigger: ['blur'] }
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'abnormalState')
    this.doGetList()
  },
  methods: {
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      ExceptionalPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    },
    saveDialog() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.loading = true
          saveExceptional(this.dialogForm).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.dialogVisible = false
              this.doGetList()
              this.fromClear()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    fromClear() {
      this.dialogForm = {
        exceptionalName: ''
      }
    },
    // 编辑回显
    queryData(row) {
      this.dialogForm = Object.assign({}, row)
    },
    // 删除
    handleDelete(index, row) {
      this.deleteObject = true
      this.dataId = row.id
    },
    removeObject() {
      this.loading = true
      deleteExceptional({ id: this.dataId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dataId = ''
          this.deleteObject = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
 