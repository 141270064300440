// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#333",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#409EFF",
	"menuBg": "#f3f7f8",
	"menuHover": "#e6efff",
	"subMenuBg": "#e6efff",
	"subMenuHover": "#c9dcff",
	"sideBarWidth": "210px"
};
module.exports = exports;
