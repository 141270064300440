<template>
  <div>
    <div class="pages">
      <div style="display: flex">
        <query @search="searchAdd" :allColumns="columns"></query>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="exportQuery"
          action="/manage/wechat/data/channelStatistics"
          :columns="columns"
          filename="数据统计-渠道统计"
        ></export-excel>
      </div>
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
        v-loading="loading"
        class="center-table"
      >
        <el-table-column
          v-for="(item, index) in columns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          width="200"
          :fixed="item.fixed"
        ></el-table-column>
      </el-table>
      <div style="text-align: center; margin: 10px" v-if="pageSize">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    label: '所在部门',
    prop: 'deptName',
    fixed: 'left'
  },
  {
    label: '课程顾问',
    prop: 'personName',
    fixed: 'left'
  },
  {
    label: '账号',
    prop: 'wechatNum',
    fixed: 'left'
  }
]
import { channelStatistics } from '@/api/statistics'
import { queryChannelList } from '@/api/data'
import Query from './components/query'
import { isEmpty } from '@/utils/utils'
export default {
  components: {
    Query
  },
  data() {
    return {
      columns,
      loading: false,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      queryForm: {},
      exportQuery: {},
      channelList: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'StatisticsChannel')
    this.getChannelList()
  },
  methods: {
    getChannelList() {
      queryChannelList().then((res) => {
        if (res.status == 200) {
          this.channelList = res.data

          if (!isEmpty(this.channelList)) {
            this.channelList.forEach((item) => {
              this.columns.push({
                prop: String(item.key),
                label: item.value
              })
            })
            this.columns.push({
              prop: 'rowFriendNum',
              label: '合计'
            })
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCurrentChange(pageNum) {
      this.getList(this.queryForm, pageNum)
    },
    searchAdd(params) {
      if (params) {
        this.queryForm = params
        this.exportQuery = params
        this.exportQuery.pageNum = 1
        this.exportQuery.pageSize = 9999
      }
      this.getList(params, 1)
    },
    getList(params, pageNum) {
      params.pageSize = this.pageSize
      params.pageNum = pageNum
      channelStatistics(params).then((res) => {
        this.loading = false
        if (res.status == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .center-table td,
::v-deep .center-table th {
  text-align: center !important;
}
</style>