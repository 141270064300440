<template>
  <div>
    <div class="pages">
      <div style="display: flex">
        <query @search="searchAdd" :allColumns="columns"></query>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="exportQuery"
          action="/manage/wechat/data/everyDayFriendNum"
          :columns="columns"
          filename="数据统计-每日统计"
        ></export-excel>
      </div>
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
        v-loading="loading"
        class="center-table"
      >
        <el-table-column
          v-for="(item, index) in columns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :width="item.width"
        >
          <template slot-scope="scope">
            <template v-if="scope.row[item.prop]">{{
              scope.row[item.prop]
            }}</template>
            <template
              v-if="
                item.prop != 'personName' &&
                item.prop != 'wechatNum' &&
                !scope.row[item.prop] &&
                (!scope.row.isLast ||
                  (scope.row.isLast && index < columns.length - 2))
              "
              >0</template
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; margin: 10px" v-if="pageSize">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
const initColumns = [
  {
    label: '所在部门',
    prop: 'deptName',
    width: '140',
    fixed: 'left'
  },
  {
    label: '课程顾问',
    prop: 'personName',
    fixed: 'left'
  },
  {
    label: '账号',
    prop: 'wechatNum',
    fixed: 'left'
  }
]
const columnsPop = [
  {
    label: '总新增好友数',
    prop: 'rowAddFriendNum'
  },
  {
    label: '总删除好友数',
    prop: 'rowDelFriendNum'
  }
]
import { everyDayFriendNum } from '@/api/statistics'
import Query from './components/query'
export default {
  components: {
    Query
  },
  data() {
    return {
      initColumns,
      columns: [],
      columnsPop,
      loading: false,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      queryForm: {},
      exportQuery: {},
      channelList: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'StatisticsEveryDay')
  },
  methods: {
    // 获取时间段之间的所有日期
    getDaysByRange() {
      let dateArray = []
      if (this.queryForm.startTime && this.queryForm.endTime) {
        let currentDate = new Date(this.queryForm.startTime)
        let endDate = new Date(this.queryForm.endTime)
        while (currentDate < endDate) {
          // 将日期对象格式化为YYYY-MM-DD
          const dateString = currentDate.toISOString().split('T')[0]
          dateArray.push(dateString)
          // 将当前日期增加一天
          currentDate.setDate(currentDate.getDate() + 1)
        }
        console.log(dateArray)
      }
      return dateArray
    },
    getColumnsByTimeRange() {
      this.columns = []
      this.getDaysByRange().forEach((item, i) => {
        let itemDay = item.split('-').pop()
        this.columns.push(
          {
            label: itemDay + '日新增好友数',
            prop: 'addFriendNum' + (i + 1)
          },
          {
            label: itemDay + '日删除好友数',
            prop: 'delFriendNum' + (i + 1)
          },
          {
            label: itemDay + '日好友总数',
            prop: 'todayFriendNum' + (i + 1)
          }
        )
      })
      this.columns = this.columns.concat(this.initColumns, this.columnsPop)
    },

    handleCurrentChange(pageNum) {
      this.getList(this.queryForm, pageNum)
    },
    searchAdd(params) {
      if (params) {
        this.queryForm = params
        this.exportQuery = params
        this.exportQuery.pageNum = 1
        this.exportQuery.pageSize = 9999
      }
      // 获取表头
      this.getColumnsByTimeRange()
      // 获取表格数据
      this.getList(params, 1)
    },
    getList(params, pageNum) {
      params.pageSize = this.pageSize
      params.pageNum = pageNum
      this.loading = true
      everyDayFriendNum(params).then((res) => {
        this.loading = false
        if (res.status == 200) {
          this.tableData = res.data.list.filter((item, index) => {
            if (index == res.data.list.length - 1) {
              item.isLast = true
            }
            return item
          })
          // this.tableData = res.data.list
          console.log(123, this.tableData)
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .center-table td,
::v-deep .center-table th {
  text-align: center !important;
}
</style>