import request from '@/utils/request'
// 跟进统计
export function followQuery(data) {
  return request({
    url: '/manage/student/followQuery',
    method: 'post',
    data
  })
}
// 渠道2级联动
export function linkageChannel(data) {
  return request({
    url: '/manage/student/linkageChannel',
    method: 'post',
    data
  })
}
// 跟进阶段列表
export function followStageQuery(data) {
  return request({
    url: '/manage/crmFollowup/followStageQuery',
    method: 'post',
    data
  })
}
// 用户状态列表
export function userStatusQuery(data) {
  return request({
    url: '/manage/crmFollowup/userStatusQuery',
    method: 'post',
    data
  })
}
// 自定义标签列表查询
export function getCustomLabelList(data) {
  return request({
    url: '/manage/crm/customLabel/pageQuery',
    method: 'post',
    data
  })
}
// 自定义标签树形结构
export function getCustomLabelTree(data) {
  return request({
    url: '/manage/crm/customLabel/queryTree',
    method: 'post',
    data
  })
}
// 自定义标签增加
export function customLabelAdd(data) {
  return request({
    url: '/manage/crm/customLabel/addObject',
    method: 'post',
    data
  })
}
// 自定义标签编辑
export function customLabelEdit(data) {
  return request({
    url: '/manage/crm/customLabel/editObject',
    method: 'post',
    data
  })
}
// 自定义标签删除
export function customLabelRemove(data) {
  return request({
    url: '/manage/crm/customLabel/removeObject',
    method: 'post',
    data
  })
}
// 自定义标签详情
export function customLabelDetails(data) {
  return request({
    url: `/manage/crm/customLabel/queryObject/${data}`,
    method: 'post',

  })
}
// 竞争对手分页
export function getCompeteList(data) {
  return request({
    url: `/manage/crm/compete/pageQuery`,
    method: 'post',
    data
  })
}
// 竞争对手添加
export function competeAdd(data) {
  return request({
    url: `/manage/crm/compete/addObject`,
    method: 'post',
    data
  })
}
// 竞争对手编辑
export function competeEdit(data) {
  return request({
    url: `/manage/crm/compete/editObject`,
    method: 'post',
    data
  })
}
// 竞争对手删除
export function competeRemove(data) {
  return request({
    url: `/manage/crm/compete/removeObject`,
    method: 'post',
    data
  })
}
// 丢单原因分页
export function getLostOrderList(data) {
  return request({
    url: `/manage/crm/lostOrder/pageQuery`,
    method: 'post',
    data
  })
}
// 丢单原因添加
export function lostOrderAdd(data) {
  return request({
    url: `/manage/crm/lostOrder/addObject`,
    method: 'post',
    data
  })
}
// 丢单原因编辑
export function lostOrderEdit(data) {
  return request({
    url: `/manage/crm/lostOrder/editObject`,
    method: 'post',
    data
  })
}
// 丢单原因删除
export function lostOrderRemove(data) {
  return request({
    url: `/manage/crm/lostOrder/removeObject`,
    method: 'post',
    data
  })
}
// 跟进物料包分页
export function getMaterialList(data) {
  return request({
    url: `/manage/crm/material/pageQuery`,
    method: 'post',
    data
  })
}
// 跟进物料包添加
export function materialAdd(data) {
  return request({
    url: `/manage/crm/material/addObject`,
    method: 'post',
    data
  })
}
// 跟进物料包编辑
export function materialEdit(data) {
  return request({
    url: `/manage/crm/material/editObject`,
    method: 'post',
    data
  })
}
// 跟进物料包删除
export function materialRemove(data) {
  return request({
    url: `/manage/crm/material/removeObject`,
    method: 'post',
    data
  })
}
// 跟进物料包详情
export function getMaterialDetails(data) {
  return request({
    url: `/manage/crm/material/queryDetails/${data}`,
    method: 'post',
  })
}
// 标记销售机会
export function saleChanceSet(data) {
  return request({
    url: `/manage/student/saleChanceSet`,
    method: 'post',
    data
  })
}
// 用户销售机会查询
export function saleChanceQry(data) {
  return request({
    url: `/manage/student/saleChanceQry`,
    method: 'post',
    data
  })
}
// 用户销售机会取消
export function cancelSaleChance(data) {
  return request({
    url: `/manage/student/cancelSaleChance`,
    method: 'post',
    data
  })
}
// 查询用户问答标签
export function queryStudentQaLabel(data) {
  return request({
    url: `/manage/student/queryStudentQaLabel`,
    method: 'post',
    data
  })
}
// 查询用户资料标签
export function queryStudentDataLabel(data) {
  return request({
    url: `/manage/student/queryStudentDataLabel`,
    method: 'post',
    data
  })
}
// 查询用户云课标签
export function queryStudentYkLabel(data) {
  return request({
    url: `/manage/student/queryStudentYkLabel`,
    method: 'post',
    data
  })
}
// 用户自定义标签
export function queryStudentCustomLabel(data) {
  return request({
    url: `/manage/student/queryStudentCustomLabel`,
    method: 'post',
    data
  })
}
// 用户信息修改记录
export function queryStudentEditLogs(data) {
  return request({
    url: `/manage/student/queryStudentEditLogs`,
    method: 'post',
    data
  })
}
// 修改用户是否重点标识  
export function editStudentKeynote(data) {
  return request({
    url: `/manage/student/editStudentKeynote`,
    method: 'post',
    data
  })
}
// 渠道权限分配  
export function sourcePowerPageQuery(data) {
  return request({
    url: `/manage/student/sourcePowerPageQuery`,
    method: 'post',
    data
  })
}
// 渠道权限添加
export function sourcePowerSave(data) {
  return request({
    url: `/manage/student/sourcePowerSave`,
    method: 'post',
    data
  })
}
// 渠道权限删除
export function sourcePowerRemove(data) {
  return request({
    url: `/manage/student/sourcePowerRemove`,
    method: 'post',
    data
  })
}