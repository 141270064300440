<template>
  <div>
    <!-- max-height="580" -->
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        style="width: 97%; margin: 0 30px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="showSelection" type="selection" width="55">
        </el-table-column>
        <el-table-column
          v-if="showIndex"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <template v-for="(item, index) in columns">
          <el-table-column
            v-if="!item.slotName && item.prop != 'index'"
            :label="item.label"
            :key="index"
            :prop="item.prop"
            :formatter="item.formatter"
            :width="item.width"
          ></el-table-column>
          <slot :name="item.slotName" v-if="item.slotName"></slot>
        </template>
        <slot name="operate"></slot>
      </el-table>
      <div style="text-align: center; margin: 10px" v-if="pageSize">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'columns',
    'tableData',
    'pageSize',
    'pageNum',
    'total',
    'showIndex', // 是否显示序号
    'showSelection' // 是否显示复选框按钮
  ],
  data() {
    return {}
  },
  methods: {
    handleCurrentChange(pageNum) {
      this.$emit('get-list', pageNum)
    },
    // 当选择项发生变化时
    handleSelectionChange(selectedRowList) {
      this.$emit('selection-change', selectedRowList)
    }
  }
}
</script>