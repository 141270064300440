var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-info" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("el-tree", {
            attrs: {
              "node-key": "id",
              "expand-on-click-node": false,
              "default-expanded-keys": _vm.keys,
              data: _vm.DeptTree,
              props: _vm.DeptTreeProps
            },
            on: { "node-click": _vm.DeptTreeNodeClick }
          })
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "head" },
          [
            _c(
              "list-query",
              {
                attrs: { "show-filter": false, "show-other": false },
                on: {
                  search: _vm.tableSearch,
                  add: function($event) {
                    _vm.Infotitle = "新增岗位"
                    _vm.operationorg = true
                    _vm.InfotableClear()
                  }
                }
              },
              [
                _c(
                  "template",
                  { slot: "default" },
                  [
                    _c(
                      "el-form",
                      { attrs: { inline: "", "auto-complete": "on" } },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入岗位名称" },
                              model: {
                                value: _vm.requestParams.postName,
                                callback: function($$v) {
                                  _vm.$set(_vm.requestParams, "postName", $$v)
                                },
                                expression: "requestParams.postName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "table-components",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                attrs: {
                  columns: _vm.columns,
                  total: _vm.total,
                  pageNum: _vm.requestParams.pageNum,
                  pageSize: _vm.requestParams.pageSize,
                  data: _vm.tableData
                },
                on: { "get-list": _vm.handleCurrentChange }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    slot: "operate",
                    label: "操作",
                    width: "300",
                    fixed: "right"
                  },
                  slot: "operate",
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.hasPermission.includes("编辑")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      _vm.operationorg = true
                                      _vm.editOrg(scope.row)
                                      _vm.Infotitle = "编辑岗位"
                                    }
                                  }
                                },
                                [_vm._v("编辑 ")]
                              )
                            : _vm._e(),
                          _vm.hasPermission.includes("删除")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDlelte(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除 ")]
                              )
                            : _vm._e(),
                          _vm.hasPermission.includes("设为主管")
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    disabled: scope.row.manageFlag === 1
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.manageFlag = true
                                      _vm.manageflag(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.manageFlag === 1
                                          ? "主管"
                                          : "设为主管"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "删除岗位", visible: _vm.removeorg, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.removeorg = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要删除该岗位吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.removeorg = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.removeOrg()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "设为主管", visible: _vm.manageFlag, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.manageFlag = $event
            }
          }
        },
        [
          _c("div", [
            _vm._v(" 当前部门主管 "),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.currentManage))
            ])
          ]),
          _c("div", [
            _vm._v(" 是否将 "),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.newtManage))
            ]),
            _vm._v(" 设置为部门主管 ")
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.manageFlag = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.editmanageFlag()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.Infotitle,
            visible: _vm.operationorg,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.operationorg = $event
            },
            close: function($event) {
              return _vm.$refs.addForm.clearValidate()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "addForm",
                  staticStyle: {
                    overflow: "hidden",
                    "padding-left": "30px",
                    display: "flex",
                    "flex-wrap": "wrap"
                  },
                  attrs: { model: _vm.addForm, rules: _vm.addFormrules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "岗位名称", prop: "postName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.postName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "postName", $$v)
                          },
                          expression: "addForm.postName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在部门", prop: "deptId" } },
                    [
                      _c("el-cascader", {
                        ref: "cascaderHandle",
                        attrs: {
                          filterable: "",
                          props: {
                            checkStrictly: true,
                            children: "childList",
                            label: "orgName",
                            value: "id"
                          },
                          options: _vm.DeptTree,
                          "show-all-levels": false
                        },
                        on: { change: _vm.MenucascaderSelectChange },
                        model: {
                          value: _vm.addForm.deptId,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "deptId", $$v)
                          },
                          expression: "addForm.deptId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级岗位", prop: "parentId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.parentId,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "parentId", $$v)
                            },
                            expression: "addForm.parentId"
                          }
                        },
                        _vm._l(_vm.PostList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.postName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "manageFlag",
                      attrs: { label: "是否部门主管", prop: "manageFlag" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addForm.manageFlag,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "manageFlag", $$v)
                            },
                            expression: "addForm.manageFlag"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.operationorg = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.doaddOrg()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }