import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

import Layout from '@/layout'
const _import = require('./router/_import_' + process.env.NODE_ENV)
console.log('process===', process)
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // 路由白名单

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {

      const hasGetUserInfo = store.getters.name
      if (!global.antRouter) {
        await store.dispatch('user/getMenuList', { menuType: "1" })  // 获取菜单
        if (store.getters.menus.length < 1) {
          global.antRouter = []
          next()
        }
        let menus = filterAsyncRouter(store.getters.menus) //1.过滤路由
        menus.push({ path: '*', redirect: '/404', hidden: true })

        router.addRoutes(menus) //2.动态添加路由

        global.antRouter = menus // 3.将路由数据传递给全局变量，做侧边栏菜单渲染工作
      } else {
        window.sessionStorage.getItem('menuSate')
      }
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')  // 获取个人信息
          next({
            ...to,
            replace: true
          })
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
//遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = Layout
      } else {
        try {
          route.component = require('@/views' + route.path + '.vue').default
        } catch {
          route.component = require('@/views/ErrorPage/404')
        }

      }
    }
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
  return accessedRouters
}
