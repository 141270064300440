var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rowFunction" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "list-query",
            {
              attrs: { "show-filter": false, "show-other": false },
              on: {
                search: _vm.tableSearch,
                add: function($event) {
                  _vm.Infotitle = "新增角色"
                  _vm.operationrow = true
                  _vm.InfotableClear()
                }
              }
            },
            [
              _c(
                "template",
                { slot: "default" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "info-form",
                      attrs: { inline: "", "auto-complete": "on" }
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入角色名称" },
                            model: {
                              value: _vm.requestParams.roleName,
                              callback: function($$v) {
                                _vm.$set(_vm.requestParams, "roleName", $$v)
                              },
                              expression: "requestParams.roleName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "table-components",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              attrs: {
                columns: _vm.columns,
                total: _vm.total,
                pageNum: _vm.requestParams.pageNum,
                pageSize: _vm.requestParams.pageSize,
                data: _vm.tableData
              },
              on: { "get-list": _vm.handleCurrentChange }
            },
            [
              _c("el-table-column", {
                attrs: {
                  slot: "operate",
                  label: "操作",
                  width: "300",
                  fixed: "right"
                },
                slot: "operate",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.hasPermission.includes("设置权限")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.saveRolemenu(scope.row)
                                    _vm.saverolemenu = true
                                  }
                                }
                              },
                              [_vm._v("设置权限 ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission.includes("编辑")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.operationrow = true
                                    _vm.editRow(scope.row)
                                    _vm.Infotitle = "编辑部门"
                                  }
                                }
                              },
                              [_vm._v("编辑 ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission.includes("删除")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.rowId = scope.row.id
                                    _vm.removerow = true
                                  }
                                }
                              },
                              [_vm._v("删除 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "删除角色", visible: _vm.removerow, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.removerow = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要删除该角色吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.removerow = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.removeRow()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "saverolemenu",
          attrs: { title: "设置权限", visible: _vm.saverolemenu, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.saverolemenu = $event
            },
            close: function($event) {
              _vm.dialoading = true
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialoading,
                  expression: "dialoading"
                }
              ],
              staticStyle: { "min-height": "400px" }
            },
            [
              _c(
                "el-tabs",
                { staticClass: "rowtabs", attrs: { "tab-position": "left" } },
                _vm._l(_vm.rowlist, function(item) {
                  return _c(
                    "el-tab-pane",
                    { attrs: { label: item.meta.title } },
                    [
                      _c(
                        "div",
                        { staticClass: "title2" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.seletedRow,
                                callback: function($$v) {
                                  _vm.seletedRow = $$v
                                },
                                expression: "seletedRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content2" },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: item.id } },
                                    [_vm._v(_vm._s(item.meta.title))]
                                  )
                                ],
                                1
                              ),
                              _vm._l(item.children, function(items) {
                                return _c("div", { staticClass: "rowlist" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { label: items.id },
                                          on: {
                                            change: function(checked) {
                                              return _vm.checkboxChange(
                                                checked,
                                                items
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(items.meta.title))]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "content" },
                                    _vm._l(items.children, function(itemss) {
                                      return _c(
                                        "el-checkbox",
                                        { attrs: { label: itemss.id } },
                                        [_vm._v(_vm._s(itemss.meta.title))]
                                      )
                                    }),
                                    1
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.saverolemenu = false
                      _vm.dialoading = true
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveRoleMenu()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.Infotitle,
            visible: _vm.operationrow,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.operationrow = $event
            },
            close: function($event) {
              return _vm.$refs.addForm.clearValidate()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "addForm",
                  staticStyle: {
                    overflow: "hidden",
                    "padding-left": "30px",
                    display: "flex",
                    "flex-wrap": "wrap"
                  },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.addFormrules,
                    inline: ""
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称", prop: "roleName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.roleName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "roleName", $$v)
                          },
                          expression: "addForm.roleName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.addForm.roleDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "roleDesc", $$v)
                          },
                          expression: "addForm.roleDesc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.operationrow = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.doaddOrg()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }