import request from '@/utils/request';

/**-------个人中心------**/
//获得专业列表
export function queryMajorType(data) {
  return request({
    url: '/manage/center/queryMajorType',
    method: 'post',
    data
  })
}

//上班打卡/修改专业
export function clockIn(data) {
  return request({
    url: '/manage/center/clockIn',
    method: 'post',
    data
  })
}

//停止问题分配
export function clockOut(data) {
  return request({
    url: '/manage/center/clockOut',
    method: 'post',
    data
  })
}

//基本信息
export function personInfo(data) {
  return request({
    url: '/manage/center/personInfo',
    method: 'post',
    data
  })
}

//下班
export function offWork(data) {
  return request({
    url: '/manage/center/offWork',
    method: 'post',
    data
  })
}

//下班打卡：问题分配
export function questTransfer(data) {
  return request({
    url: '/manage/center/questTransfer',
    method: 'post',
    data
  })
}
/**-------正在回答------**/

//分页查询
export function queryGroupList(data) {
  return request({
    url: '/manage/center/questionPage',
    method: 'post',
    data
  })
}

//问题超纲
export function aboveLevel(data) {
  return request({
    url: '/manage/center/aboveLevel',
    method: 'post',
    data
  })
}
//申请关闭
export function applyClose(data) {
  return request({
    url: '/manage/center/applyClose',
    method: 'post',
    data
  })
}
//加知识库
export function joinKnowledge(data) {
  return request({
    url: '/manage/center/joinKnowledge',
    method: 'post',
    data
  })
}


//问题转移
export function questionShifting(data) {
  return request({
    url: '/manage/center/questionShifting',
    method: 'post',
    data
  })
}

//回答问题
export function answerQuestion(data) {
  return request({
    url: '/manage/center/answerQuestion',
    method: 'post',
    data
  })
}

//隐藏消息
export function hidequeOrAns(data) {
  return request({
    url: '/manage/question/hidequeOrAns',
    method: 'post',
    data
  })
}

/**-------回答记录------**/
//分页查询
export function qaRecordPage(data) {
  return request({
    url: '/manage/center/qaRecordPage',
    method: 'post',
    data
  })
}
//导出
export function qaRecordExport(data) {
  return request({
    url: '/manage/center/qaRecordExport',
    method: 'post',
    data
  })
}
/**-------历史评价------**/
// 分页查询
export function evaluatePage(data) {
  return request({
    url: '/manage/center/evaluatePage',
    method: 'post',
    data
  })
}
/**-------回答审核------**/
// 分页查询
export function examinePage(data) {
  return request({
    url: '/manage/center/examinePage',
    method: 'post',
    data
  })
}

// 审批通过
export function applyPassed(data) {
  return request({
    url: '/manage/center/applyPassed',
    method: 'post',
    data
  })
}
// 审批驳回
export function applyRejected(data) {
  return request({
    url: '/manage/center/applyRejected',
    method: 'post',
    data
  })
}
/**-------我的消息------**/
// 分页查询
export function queryUnreadList(data) {
  return request({
    url: '/manage/sys/message/queryUnreadList',
    method: 'post',
    data
  })
}
//未读消息
export function queryUnreadNum(data) {
  return request({
    url: 'manage/sys/message/queryUnreadNum',
    method: 'post',
    data
  })
}
// 判断问题是否存在
export function queDoesItExist(data) {
  return request({
    url: '/manage/center/queDoesItExist',
    method: 'post',
    data
  })
}
// 线索推送
export function cluePush(data) {
  return request({
    url: '/manage/center/cluePush',
    method: 'post',
    data
  })
}

// 消息详情
export function userMsgInfo(data) {
  return request({
    url: '/manage/sys/message/userMsgInfo',
    method: 'post',
    data
  })
}


// 问题答案草稿入库
export function saveDraftAnswer(data) {
  return request({
    url: '/manage/center/saveDraftAnswer',
    method: 'post',
    data
  })
}
/**-------隐藏审核------**/
// 分页查询
export function examineClientHide(data) {
  return request({
    url: '/manage/center/examineClientHide',
    method: 'post',
    data
  })
}

// 审批
export function applyExamine(data) {
  return request({
    url: '/manage/center/applyExamine',
    method: 'post',
    data
  })
}

// =======================待办任务=========================
// 分配任务-模板下载
export function templateDownload() {
  return request({
    url: '/manage/personTask/templateDownload',
    method: 'post',
    responseType: 'blob'
  })
}
// 分配任务-模板数据导入
export function taskImport(data) {
  return request({
    url: '/manage/personTask/taskImport',
    method: 'post',
    data
  })
}
// 我的任务
export function myTaskPage(data) {
  return request({
    url: '/manage/personTask/myTaskPage',
    method: 'post',
    data
  })
}
// 任务监控
export function taskMonitoringPage(data) {
  return request({
    url: '/manage/personTask/taskMonitoringPage',
    method: 'post',
    data
  })
}
// 任务监控导出
export function taskMonitoringExport(data) {
  return request({
    url: '/manage/personTask/taskMonitoringExport',
    method: 'post',
    data
  })
}

// =======================教师管理=========================
///在线教师
// 在线教师列表
export function onLineTeacherQuery(data) {
  return request({
    url: '/manage/center/onLineTeacherQuery',
    method: 'post',
    data
  })
}

//打卡记录
export function personPunchQuery(data) {
  return request({
    url: '/manage/center/personPunchQuery',
    method: 'post',
    data
  })
}

//教师评价
export function evaluateTeacherQuery(data) {
  return request({
    url: '/manage/center/evaluateTeacherQuery',
    method: 'post',
    data
  })
}
//教师评价-导出
export function evaluateTeacherExport(data) {
  return request({
    url: '/manage/center/evaluateTeacherExport',
    method: 'post',
    data
  })
}
// =======================删除审核=========================
//问题申请删除-分页
export function applyRemovePage(data) {
  return request({
    url: '/manage/question/applyRemovePage',
    method: 'post',
    data
  })
}
//问题删除审批
export function removeExamine(data) {
  return request({
    url: '/manage/question/removeExamine',
    method: 'post',
    data
  })
}
//教师管理-休息记录
export function personRestQuery(data) {
  return request({
    url: '/manage/center/personRestQuery',
    method: 'post',
    data
  })
}


