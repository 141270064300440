var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sourceChannel" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-form",
            { staticClass: "info-form", attrs: { "auto-complete": "on" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索渠道名称" },
                    model: {
                      value: _vm.requestParams.attrCname,
                      callback: function($$v) {
                        _vm.$set(_vm.requestParams, "attrCname", $$v)
                      },
                      expression: "requestParams.attrCname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "20px 10px 0 20px", float: "left" },
                  attrs: { type: "primary" },
                  on: { click: _vm.tableSearch }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "20px 10px", float: "left" },
                  attrs: { type: "primary" },
                  on: { click: _vm.tableClear }
                },
                [_vm._v("清空")]
              ),
              _vm.hasPermission.includes("新增")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "20px 10px", float: "left" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.operationChannel = true
                          _vm.InfoTitle = "新增渠道"
                          _vm.readonly = false
                          _vm.InfoTableClear()
                        }
                      }
                    },
                    [_vm._v("新增 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              staticStyle: { width: "97%", margin: "0 30px" },
              attrs: {
                "header-cell-style": { textAlign: "center" },
                "cell-style": { textAlign: "center" },
                data: _vm.tableData,
                "max-height": "630",
                border: ""
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "number", width: "50", label: "序号" }
              }),
              _vm._l(_vm.column, function(item, index) {
                return [
                  _c("el-table-column", {
                    key: index,
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: item.prop,
                      label: item.label
                    }
                  })
                ]
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "520" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.hasPermission.includes("排序")
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.UpOrDownMajor("up", scope.row)
                                  }
                                }
                              },
                              [_vm._v("上升 ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission.includes("排序")
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.UpOrDownMajor("down", scope.row)
                                  }
                                }
                              },
                              [_vm._v("下降 ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission.includes("编辑")
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.operationChannel = true
                                    _vm.InfoTitle = "编辑渠道"
                                    _vm.queryChannel(scope.row)
                                    _vm.readonly = false
                                  }
                                }
                              },
                              [_vm._v("编辑 ")]
                            )
                          : _vm._e(),
                        _vm.hasPermission.includes("删除")
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("删除 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.requestParams.pageSize,
                  "current-page": _vm.requestParams.pageNum,
                  total: _vm.total
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "删除渠道",
            visible: _vm.deleteChannel,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteChannel = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要删除该渠道吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteChannel = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.removeChannel()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.InfoTitle,
            visible: _vm.operationChannel,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.operationChannel = $event
            },
            close: function($event) {
              return _vm.$refs.addForm.clearValidate()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "addForm",
                  staticStyle: { overflow: "hidden", "padding-left": "30px" },
                  attrs: { model: _vm.addForm, rules: _vm.addFormRules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道名称", prop: "attrCname" } },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: _vm.readonly,
                          placeholder: "请输入"
                        },
                        model: {
                          value: _vm.addForm.attrCname,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "attrCname", $$v)
                          },
                          expression: "addForm.attrCname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道分类", prop: "channelType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.readonly,
                            placeholder: "请选择渠道分类"
                          },
                          model: {
                            value: _vm.addForm.channelType,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "channelType", $$v)
                            },
                            expression: "addForm.channelType"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "运营", value: "运营" }
                          }),
                          _c("el-option", {
                            attrs: { label: "市场", value: "市场" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序值", prop: "channelSort" } },
                    [
                      _c("el-input", {
                        attrs: {
                          readonly: _vm.readonly,
                          placeholder: "请输入"
                        },
                        model: {
                          value: _vm.addForm.channelSort,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "channelSort", $$v)
                          },
                          expression: "addForm.channelSort"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.operationChannel = false
                      _vm.readonly = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.saveChannel()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }