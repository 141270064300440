import Cookies from 'js-cookie'
import OSS from 'ali-oss'
const TokenKey = 'managementToken'
let domains = ''
if (process.env.NODE_ENV == 'development') {
  domains = '.qcgcjs.com'
} else {
  domains = '.qichengzaixian.com'
}
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {

  Cookies.set(TokenKey, token)
  return Cookies.set(TokenKey, token, { domain: domains })
}

export function removeToken() {
  Cookies.remove(TokenKey)
  return Cookies.remove(TokenKey, { domain: domains })
}
export function setCookie(k, v, d) {
  var millisecond = new Date().getTime();
  var expiresTime = new Date(millisecond + 60 * 1000 * d);
  return Cookies.set(k, v, {
    expires: expiresTime,
  })
}
export function getCookie(k) {
  return Cookies.get(k)
}
