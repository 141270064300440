<template>
  <div class="rowFunction">
    <div class="head">
        <list-query
          :show-filter="false"
          :show-other="false"
          @search="tableSearch"
          @add="
            Infotitle = '新增角色'
            operationrow = true
            InfotableClear()
        "
        >
          <template slot="default">
            <el-form class="info-form" inline auto-complete="on">
              <el-form-item>
                <el-input
                  v-model="requestParams.roleName"
                  placeholder="请输入角色名称"
                ></el-input>
              </el-form-item>
            </el-form>
          </template></list-query>

    </div>
    <div class="content">
      <table-components
        :columns="columns"
        :total="total"
        :pageNum="requestParams.pageNum"
        :pageSize="requestParams.pageSize"
        :data="tableData"
        v-loading="listLoading"
        @get-list="handleCurrentChange"
      >
        <el-table-column
          label="操作"
          width="300"
          slot="operate"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-if="hasPermission.includes('设置权限')"
              @click="
                saveRolemenu(scope.row)
                saverolemenu = true
              "
            >设置权限
            </el-button>

            <el-button
              type="primary"
              size="mini"
              v-if="hasPermission.includes('编辑')"
              @click="
                operationrow = true
                editRow(scope.row)
                Infotitle = '编辑部门'
              "
            >编辑
            </el-button>

            <el-button
              type="primary"
              size="mini"
              v-if="hasPermission.includes('删除')"
              @click="
                rowId = scope.row.id
                removerow = true
              "
            >删除
            </el-button>
          </template></el-table-column>
      </table-components>

    </div>

    <!--    弹出框-->
    <el-dialog title="删除角色" :visible.sync="removerow" width="25%">
      <div>确定要删除该角色吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removerow = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeRow()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="设置权限"
      :visible.sync="saverolemenu"
      class="saverolemenu"
      @close="dialoading = true"
      width="50%"
    >
      <div style="min-height: 400px" v-loading="dialoading">
        <el-tabs class="rowtabs" tab-position="left">
          <el-tab-pane v-for="item in rowlist" :label="item.meta.title">
            <div class="title2">
              <el-checkbox-group v-model="seletedRow">
                <div class="content2">
                  <el-checkbox :label="item.id">{{
                    item.meta.title
                  }}</el-checkbox>
                </div>
                <div class="rowlist" v-for="items in item.children">
                  <div class="title">
                    <el-checkbox
                      @change="(checked) => checkboxChange(checked, items)"
                      :label="items.id"
                      >{{ items.meta.title }}</el-checkbox
                    >
                  </div>
                  <div class="content">
                    <el-checkbox
                      v-for="itemss in items.children"
                      :label="itemss.id"
                      >{{ itemss.meta.title }}</el-checkbox
                    >
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            saverolemenu = false
            dialoading = true
          "
          >取 消</el-button
        >
        <el-button type="primary" :loading="loading" @click="saveRoleMenu()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="Infotitle"
      :visible.sync="operationrow"
      @close="$refs.addForm.clearValidate()"
      width="25%"
    >
      <div>
        <el-form
          :model="addForm"
          :rules="addFormrules"
          ref="addForm"
          class="addForm"
          inline
          style="
            overflow: hidden;
            padding-left: 30px;
            display: flex;
            flex-wrap: wrap;
          "
        >
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="addForm.roleName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="备注信息">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="addForm.roleDesc"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operationrow = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="doaddOrg()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteRole,
  editRole,
  findPostList,
  roleAdd,
  rolePage,
  saveRoleMenu,
  tmenuTree
} from '@/api/organization'
import { findRoleMenuButton } from '@/api/user'
const columns=[
  {
    label: '角色名称',
    prop: 'roleName',
    width:'auto'
  },
  {
    label: '备注信息',
    prop: 'roleDesc',
  width:'auto'
  },
]
export default {
  name: 'FunctionAuthority',
  data() {
    return {
      columns,
      dialoading: true,
      hasPermission: [],
      loading: false,
      Infotitle: '',
      listLoading: false,
      //表格
      tableData: [],
      requestParams: {
        pageNum: 1,
        pageSize: 10,
        roleName: ''
      },
      total: 0,
      //按钮
      operationrow: false,

      //角色新增
      addForm: {
        roleName: '',
        roleDesc: ''
      },
      addFormrules: {
        roleName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: ['blur', 'change']
          },
          {
            min: 2,
            max: 32,
            message: '请输入2-32个字符',
            trigger: ['blur', 'change']
          }
        ]
      },
      rowId: '',
      //删除角色
      removerow: false,

      saverolemenu: false,
      rowlist: '',
      seletedRow: []
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'FunctionAuthority')

    this.doGetList()
    findPostList({
      pageNum: 1,
      pageSize: 99999
    }).then((res) => {
      this.PostList = res.data.list
    })
    findRoleMenuButton({ name: 'FunctionAuthority' }).then((res) => {
      this.hasPermission = res.data
    })
  },
  methods: {
    doGetList() {
      this.listLoading = true
      rolePage(this.requestParams).then((data) => {
        this.tableData = data.data.list
        this.requestParams.pageNum = data.data.current
        this.tableData.forEach((item, index) => {
          item.number =
            (this.requestParams.pageNum - 1) * data.data.pageSize + index + 1
        })
        this.total = data.data.total
        this.listLoading = false
      })
    },

    // 分页跳转
    handleCurrentChange(val) {
      this.requestParams.pageNum = val
      this.doGetList()
    },

    //表格搜素
    tableSearch() {
      this.requestParams.pageNum = 1
      this.doGetList()
    },

    //清空搜索表单并搜索
    tableClear() {
      this.requestParams = {
        pageNum: 1,
        pageSize: 10,
        roleName: ''
      }
      this.doGetList()
    },
    // 新增、编辑角色
    doaddOrg() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.Infotitle === '新增角色') {
            roleAdd(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationrow = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          } else {
            this.addForm.id = this.rowId
            this.rowId = ''
            editRole(this.addForm).then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: res.message,
                  type: 'success'
                })
                this.operationrow = false
                this.doGetList()
              } else {
                this.$message.error(res.message)
              }
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    },

    //清空查看详情
    InfotableClear() {
      this.addForm = {
        roleName: '',
        roleDesc: ''
      }
    },

    // 删除角色
    removeRow() {
      this.loading = true
      deleteRole({ id: this.rowId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.rowId = ''
          this.removerow = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    // 编辑角色
    editRow(row) {
      this.rowId = row.id
      this.addForm.roleName = row.roleName
      this.addForm.roleDesc = row.roleDesc
    },

    saveRolemenu(row) {
      this.rowId = row.id
      this.seletedRow = []
      tmenuTree({ roleId: this.rowId }).then((res) => {
        if (res.status == 200) {
          this.rowlist = res.data
          // this.rowlist.splice(0,1)
          this.rowlist.forEach((item) => {
            if (item.isCheck === '1') {
              this.seletedRow.push(item.id)
            }
            if (item.children) {
              item.children.forEach((items) => {
                if (items.isCheck === '1') {
                  this.seletedRow.push(items.id)
                }
                if (items.children) {
                  items.children.forEach((itemss) => {
                    if (itemss.isCheck === '1') {
                      this.seletedRow.push(itemss.id)
                    }
                  })
                }
              })
            }
          })
          this.dialoading = false
        } else {
          this.dialoading = false
          this.$message.error(res.message)
        }
      })
    },
    //设置权限
    saveRoleMenu() {
      let roleMenuList = []
      console.log(this.seletedRow)
      this.seletedRow.forEach((item) => roleMenuList.push({ menuId: item }))
      this.loading = true
      saveRoleMenu({
        id: this.rowId,
        roleMenuList: roleMenuList
      }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.rowId = ''
          this.seletedRow = []
          this.saverolemenu = false
          // window.location.reload()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    },
    checkboxChange(checked, items) {
      this.seletedRow.sort()
      if (checked === true && items.children) {
        items.children.forEach((item) => {
          if (item.isCheck === '0') {
            item.isCheck = '1'
            this.seletedRow.push(item.id)
          }
        })
      } else if (checked === false && items.children) {
        items.children.forEach((item) => {
          if (item.isCheck === '1') {
            item.isCheck = '0'
            if (this.seletedRow.includes(item.id)) {
              this.seletedRow = this.seletedRow.filter((a) => a !== item.id)
            }
          }
        })
      }
    }
  }
}
</script>


<style lang="scss">
.rowFunction .el-form-item__content {
  width: 200px;
}

.rowFunction .el-form-item__label {
  width: 115px;
}

.rowFunction .addForm .jzhynx .el-form-item__label {
  width: 80px;
  margin-left: 35px;
  line-height: 20px;
}

.rowFunction .el-textarea__inner {
  max-height: 50px !important;
}
.rowFunction .saverolemenu .el-dialog {
  min-height: 400px;
}
</style>

<style lang="scss" scoped>
.title2 {
  border: 1px solid #e2e2e2;
  .content2 {
    font-weight: 700;
    color: #0e81c1;
    border-bottom: 2px solid #409eff;
    background: #f3f7f8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
  }
}


.addForm .el-form-item {
  margin-right: 50px;
}
.rowtabs .rowlist {
  margin: 10px 5px;
  margin-top: 0;
  .title {
    border: 1px solid #e7e7e7;
    padding: 8px 20px;
    background-color: #f1f1f1;
    border-bottom: none;
  }
  .content {
    border: 1px solid #e7e7e7;
    padding: 8px 20px;
  }
}
</style>
