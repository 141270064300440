import request from '@/utils/request'

/*-----用户管理-学生管理-----*/

// 用户管理-根据当前用户身份查询渠道
export function queryChannelByIdentity(data) {
  return request({
    url: '/manage/student/queryChannelByIdentity',
    method: 'post',
    data
  })
}
// 用户管理-学生日志列表查看
export function findStudentLogs(data) {
  return request({
    url: '/manage/student/findStudentLogs',
    method: 'post',
    data
  })
}

// 用户管理-学生列表
export function getStudentlist(data) {
  return request({
    url: '/manage/student/pageQuery',
    method: 'post',
    data
  })
}
// 学生列表--部分字段
export function queryStudentPage(data) {
  return request({
    url: '/manage/student/queryStudentPage',
    method: 'post',
    data
  })
}
//提问卡发卡绑定
export function bindStudent(data) {
  return request({
    url: '/manage/qacard/bindStudent',
    method: 'post',
    data
  })
}
//下载卡发卡绑定
export function grantDownCard(data) {
  return request({
    url: '/manage/qacard/grantDownCard',
    method: 'post',
    data
  })
}

// 用户管理-学生来源
export function getStudentSource() {
  return request({
    url: '/manage/queryStudentSource',
    method: 'get',
  })
}
// 用户管理-获取课程顾问列表
export function queryConsultantList(data) {
  return request({
    url: '/manage/consultant/queryConsultantList',
    method: 'post',
    data
  })
}
// 用户管理-学历查询
export function queryEducation() {
  return request({
    url: '/manage/queryEducation',
    method: 'get'
  })
}

// 用户管理-学生添加
export function insertStudent(data) {
  return request({
    url: '/manage/student/insertStudent',
    method: 'post',
    data
  })
}

// 用户管理-信息分配
export function bindingConsultant(data) {
  return request({
    url: '/manage/student/bindingConsultant',
    method: 'post',
    data
  })
}
// 用户管理-绑定课程
export function bindingCourseQa(data) {
  return request({
    url: '/manage/student/bindingCourseQa',
    method: 'post',
    data
  })
}

// 用户管理-删除学生
export function removeStudent(data) {
  return request({
    url: '/manage/student/removeStudent/' + data,
    method: 'post',
  })
}

// 用户管理-编辑学生
export function editStudent(data) {
  return request({
    url: '/manage/student/editStudent',
    method: 'post',
    data
  })
}

// 用户管理-查看详情
export function queryStudentInfo(data) {
  return request({
    url: '/manage/student/queryStudentInfo/' + data,
    method: 'post',
  })
}

// 用户管理-下发提问卡
export function batchAdd(data) {
  return request({
    url: '/manage/qacard/batchAdd',
    method: 'post',
    data
  })
}

// 用户管理-下发下载卡
export function batchGrantDownCard(data) {
  return request({
    url: '/manage/qacard/batchGrantDownCard',
    method: 'post',
    data
  })
}

// 发放积分
export function grantIntegral(data) {
  return request({
    url: '/manage/student/grantIntegral',
    method: 'post',
    data
  })
}

// 修改学员手机号
export function modifiedUserPhone(data) {
  return request({
    url: '/manage/student/modifiedUserPhone',
    method: 'post',
    data
  })
}

/*---学员跟进---*/
// 学员跟进-跟进记录添加
export function saveRecord(data) {
  return request({
    url: '/manage/crmFollowup/saveRecord',
    method: 'post',
    data
  })
}
// 学员跟进-指定学员的最新跟进记录
export function queryNewest(data) {
  return request({
    url: '/manage/crmFollowup/queryNewest',
    method: 'post',
    data
  })
}
// 学员跟进-跟进记录查询
export function FollowedPageQuery(data) {
  return request({
    url: '/manage/crmFollowup/pageQuery',
    method: 'post',
    data
  })
}


/*-----用户管理-教师管理-----*/

// 用户管理-教师列表
export function getTeacherlist(data) {
  return request({
    url: '/manage/teacher/pageQuery',
    method: 'post',
    data
  })
}

// 用户管理-删除教师
export function deletedTeacher(data) {
  return request({
    url: '/manage/teacher/deletedTeacher/' + data,
    method: 'post',
  })
}

// 用户管理-教师添加
export function addTeacher(data) {
  return request({
    url: '/manage/teacher/addTeacher',
    method: 'post',
    data
  })
}

// 用户管理-搜索非教师员工
export function searchUnTeacher(data) {
  return request({
    url: '/manage/teacher/searchUnTeacher',
    method: 'post',
    data
  })
}
// 用户管理-教师管理上升/下移
export function upDownSortTeacher(data) {
  return request({
    url: '/manage/teacher/upDownSort',
    method: 'post',
    data
  })
}
/*-----用户管理-课程顾问-----*/

// 用户管理-课程顾问列表
export function getConsultantlist(data) {
  return request({
    url: '/manage/consultant/pageQuery',
    method: 'post',
    data
  })
}

// 用户管理-删除课程顾问
export function deletedConsultant(data) {
  return request({
    url: '/manage/consultant/deletedConsultant/' + data,
    method: 'post',
  })
}

// 用户管理-添加课程顾问
export function insertConsultant(data) {
  return request({
    url: '/manage/consultant/insertConsultant',
    method: 'post',
    data
  })
}

// 用户管理-搜索非课程顾问
export function searchUnConsultant(data) {
  return request({
    url: '/manage/consultant/searchUnConsultant',
    method: 'post',
    data
  })
}
// 用户管理-查看课程顾问详情
export function consultantInfo(data) {
  return request({
    url: '/manage/consultant/consultantInfo/' + data,
    method: 'post',
  })
}


/*-----用户管理-运营管理-----*/

// 获取非运营的员工
export function qryUnOperate(data) {
  return request({
    url: 'manage/operate/qryUnOperate',
    method: 'post',
    data
  })
}
// 运营分页查询
export function qryPage(data) {
  return request({
    url: '/manage/operate/qryPage',
    method: 'post',
    data
  })
}
//运营人员 删除
export function deletedOperate(data) {
  return request({
    url: '/manage/operate/deletedOperate/' + data,
    method: 'post',
  })
}
//运营人员 添加
export function addOperate(data) {
  return request({
    url: '/manage/operate/addOperate',
    method: 'post',
    data
  })
}

/*-----用户管理-平台黑名单-----*/
//黑名单列表查询
export function blickList(data) {
  return request({
    url: '/manage/blacklist/pageQuery',
    method: 'post',
    data
  })
}
//解除黑名单
export function relieveBlacklist(data) {
  return request({
    url: '/manage/blacklist/relieveBlacklist',
    method: 'post',
    data
  })
}
//拉入黑名单
export function addBlacklist(data) {
  return request({
    url: '/manage/blacklist/addBlacklist',
    method: 'post',
    data
  })
}
//用户有效期列表--服务设置
export function qryStudentCourse(data) {
  return request({
    url: '/manage/student/qryStudentCourse',
    method: 'post',
    data
  })
}
//修改用户有效期列表--服务设置
export function editStudentCourse(data) {
  return request({
    url: '/manage/student/editStudentCourse',
    method: 'post',
    data
  })
}
//课程包列表查询
export function queryPackageList(data) {
  return request({
    url: '/manage/course/queryPackageList',
    method: 'post',
    data
  })
}
//课程列表查询
export function queryList(data) {
  return request({
    url: '/manage/course/queryList',
    method: 'post',
    data
  })
}
//条件查询学员
export function qryStuListByParam(data) {
  return request({
    url: '/manage/student/qryStuListByParam',
    method: 'post',
    data
  })
}
//课程批量设置用户
export function batchSetupStudentCourse(data) {
  return request({
    url: '/manage/student/batchSetupStudentCourse',
    method: 'post',
    data
  })
}
// 查询用户信息
export function queryStudentById(data) {
  return request({
    url: '/manage/student/queryStudentById',
    method: 'post',
    data
  })
}
// 线索转移
export function studntTransfer(data) {
  return request({
    url: '/manage/consultant/studntTransfer',
    method: 'post',
    data
  })
}
// 线索分配
export function clueDistribution(data) {
  return request({
    url: '/manage/student/clueDistribution',
    method: 'post',
    data
  })
}
// 线索管理-学习日志
export function qryCourseStudyLogs(data) {
  return request({
    url: '/manage/student/qryCourseStudyLogs',
    method: 'post',
    data
  })
}
// 线索管理-课程信息及学习进度
export function qryCourseAndStudySpeed(data) {
  return request({
    url: '/manage/student/qryCourseAndStudySpeed',
    method: 'post',
    data
  })
}
// 线索管理-学习情况-阶段列表
export function qryPackageStageList(data) {
  return request({
    url: '/manage/student/qryPackageStageList',
    method: 'post',
    data
  })
}
// 线索管理-学习情况-课程列表
export function qryStageCourseList(data) {
  return request({
    url: '/manage/student/qryStageCourseList',
    method: 'post',
    data
  })
}
// 线索管理-学习情况-章列表
export function qryChapterList(data) {
  return request({
    url: '/manage/student/qryChapterList',
    method: 'post',
    data
  })
}
// 线索管理-学习情况-节列表
export function qrySectionList(data) {
  return request({
    url: '/manage/student/qrySectionList',
    method: 'post',
    data
  })
}
// 线索管理-删除到回收站
export function addToPublicPool(data) {
  return request({
    url: `/manage/student/addToPublicPool`,
    method: 'post',
    data
  })
}
// 跟进记录编辑
export function updateRecord(data) {
  return request({
    url: `/manage/crmFollowup/updateRecord`,
    method: 'post',
    data
  })
}
// 跟进记录日志查询
export function queryRecordLogs(data) {
  return request({
    url: `/manage/crmFollowup/queryRecordLogs`,
    method: 'post',
    data
  })
}
// 跟进记录详情
export function queryRecord(data) {
  return request({
    url: `/manage/crmFollowup/queryRecord`,
    method: 'post',
    data
  })
}
// 全部课程顾问列表
export function queryAllConsultant(data) {
  return request({
    url: `/manage/consultant/queryAllConsultant`,
    method: 'post',
    data
  })
}