/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPhone(str) {
  return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(str)
}

export function validPassword(str) {
  return /^(?![a-zA-Z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/.test(str)
}

export function formatDate(value) {
  if (typeof (value) == 'undefined') {
    return ''
  } else {
    let date = new Date(parseInt(value))
    let y = date.getFullYear()
    let MM = date.getMonth() + 1
    MM = MM < 10 ? ('0' + MM) : MM
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let m = date.getMinutes()
    m = m < 10 ? ('0' + m) : m
    let s = date.getSeconds()
    s = s < 10 ? ('0' + s) : s
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
  }
}
// 校验网址格式
export function validSite(str) {
  return /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/.test(str)
}
