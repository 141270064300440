<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <audio
      src="http://qcjy-portal.oss-cn-beijing.aliyuncs.com/questionFiles/1652348774123lH0ArQ.mp3"
      id="audio"
    ></audio>
  </div>
</template>

<script>
import store from '@/store'
import { getbaseurl } from '@/utils/request'
import { getToken } from '@/utils/auth'
import { tokenEffectiveQry } from '@/api/SystemManager'
import Cookies from 'js-cookie'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      id: '',
      flag: true,
      heartBeatTimer: null
    }
  },
  computed: {
    updateMsg() {
      return store.state.user.info.id
    }
  },
  watch: {
    updateMsg(id) {
      if (id) {
        this.id = id
        this.initWebSocket()
        const hasToken = getToken()
        if (hasToken) {
          /*if(window.location.href.indexOf('login')!==-1){
            return
          }*/
          if (store.state.user.info.workStatus === 1) {
            this.$confirm(
              '当前状态无法接收到新问题，请先选择专业，点击上班或继续分配问题',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }
            )
              .then(() => {
                window.sessionStorage.setItem('menuSate', '0')
                this.$router.push('/PersonalCenter/index')
                this.reload()
              })
              .catch((err) => {})
          }
        }
      }
    }
  },
  mounted() {
    Date.prototype.Format = function (fmt) {
      // author: meizz
      var o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
      return fmt
    }
    console.log('环境', process.env.VUE_APP_BASE_API)
    let _this = this
    window.onbeforeunload = function () {
      // clockOut()
      _this.websock.close()
    }
    document.addEventListener(
      'mousewheel',
      function (e) {
        e = e || window.event
        if ((e.wheelDelta && event.ctrlKey) || e.detail) {
          event.preventDefault()
        }
      },
      {
        capture: false,
        passive: false
      }
    )
    document.addEventListener(
      'keydown',
      function (event) {
        if (
          (event.ctrlKey === true || event.metaKey === true) &&
          (event.keyCode === 61 ||
            event.keyCode === 107 ||
            event.keyCode === 173 ||
            event.keyCode === 109 ||
            event.keyCode === 187 ||
            event.keyCode === 189)
        ) {
          event.preventDefault()
        }
      },
      false
    )
    // this.heartBeatTimer = setInterval(() => {
    //   if (Cookies.get('managementToken')) {
    //     this.tokenEffectiveQryFn()
    //   }
    // }, 3500)
  },
  methods: {
    // 心跳
    tokenEffectiveQryFn() {
      tokenEffectiveQry().then((res) => {
        if (res.status == 200 && res.data == false) {
          this.$message.warning('您的账号已在别处登录，被迫下线')
          Cookies.remove('managementToken')
          Cookies.remove('accessKeyId')
          Cookies.remove('secretToken')
          Cookies.remove('userId')
          Cookies.remove('accessKeySecret')
          clearInterval(this.heartBeatTimer)
          this.heartBeatTimer = null
          this.$router.push('/login')
        }
      })
    },
    aplayAudio() {
      const audio = document.getElementById('audio')
      audio.play()
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        store.dispatch('user/getInfo') // 获取个人信息
        this.isRouterAlive = true
      })
    },
    initWebSocket() {
      //初始化weosocket
      this.websock = new WebSocket(getbaseurl(this.id), [
        'cdbbfa70a6f766d8f05619f81f638882bb4bd4fc'
      ])
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      let _this = this

      console.log('连接成功')
      clearInterval(this.timer)
      this.flag = true
      let myDate = new Date()
      console.log(myDate.toLocaleString())

      this.$store.commit('user/SET_SOCKET', '1')
      store.commit('user/SET_SOCKETTIME', Date.parse(new Date()))
      clearInterval(this.timer3)
      this.timer3 = setInterval(function () {
        _this.$store.commit('user/SET_SOCKET', '1,Refresh')
        store.commit('user/SET_SOCKETTIME', Date.parse(new Date()))
      }, 20000)

      clearInterval(this.timer2)
      //发送消息
      this.timer2 = setInterval(function () {
        _this.websock.send('ping')
      }, 30000)
      // 30秒心跳
    },
    websocketonerror(event) {
      console.log(event)
    },
    websocketonmessage(e) {
      console.log(e)
      //业务需求，将socket接收到的值存进vuex
      this.$store.commit('user/SET_SOCKET', e.data)
      store.commit('user/SET_SOCKETTIME', Date.parse(new Date()))
      // console.log(e.data);
      // let myDate = new Date();
      // console.log(myDate.toLocaleString());
      if (e.data != 'pong') {
        this.aplayAudio()
      }
      if (e.data == '3') {
        this.$notify.info({
          title: '提示',
          message:
            '温馨提示：为了您的身心健康，账号在5分钟后自动下班，如您有未完成的问答，请下班后重新执行上班操作；',
          duration: 0
        })
      } else if (e.data == '4') {
        this.$notify({
          title: '提示',
          message: '温馨提示：夜已深，您该休息了!',
          type: 'warning'
        })
        clearTimeout(timer)
        let timer = setTimeout(function () {
          clearTimeout(timer)
          window.location.reload()
        }, 3000)
      } else if (e.data == '8') {
        this.$notify({
          title: '提示',
          message: '您有一条新线索分配提醒!',
          type: 'warning'
        })
      } else if (e.data == '9') {
        this.$notify({
          title: '提示',
          message: '您有一条订单被通过/驳回，请及时查看!',
          type: 'warning'
        })
      } else if (e.data == '10') {
        this.$notify({
          title: '提示',
          message: '您有一条订单申请绑定被通过/驳回，请及时查看!',
          type: 'warning'
        })
      } else if (e.data == '11') {
        this.$notify({
          title: '提示',
          message: '您有一条订单申请判单被通过/驳回，请及时查看!',
          type: 'warning'
        })
      } else if (e.data == '1000') {
        this.$message.warning('您的账号已在别处登录，被迫下线')
        Cookies.remove('managementToken')
        Cookies.remove('accessKeyId')
        Cookies.remove('secretToken')
        Cookies.remove('userId')
        Cookies.remove('accessKeySecret')
        this.$router.push('/login')
      }
    },
    websocketclose(e) {
      //关闭
      console.log('断开连接', e)
      clearInterval(this.timer2)
      let myDate = new Date()
      console.log(myDate.toLocaleString())

      if (this.id) {
        if (this.flag) {
          // let cont=0
          clearInterval(this.timer)
          this.flag = false
          let _this = this
          _this.timer = setInterval(function () {
            _this.initWebSocket()

            // websocket重连次数
            /* cont++
            if (cont>=10){
              clearInterval(_this.timer)
              _this.$notify.error({
                title: '网络连接断开',
                message: '请检查网络',
                duration: 0
              });
              return
            }*/
          }, 10000)
        }
      } else {
        clearInterval(this.timer)
        this.flag = true
        this.$message.error('网络断开连接，请检查网络')
        return
      }
    }
  }
}
</script>
<style lang="scss">
@import '~@/assets/iconfont/iconfont.css';
.head {
  margin: 0 0 20px;
}
.content {
  padding-bottom: 0;
}
.el-button--mini,
.el-button--mini.is-round,
.el-button--small {
  height: 28px;
  margin: 5px;
}
.table-overflow-tooltip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
<style>
.modularTitle {
  color: rgb(14, 129, 193);
  background-color: rgb(242 240 247);
  border: 1px solid rgb(204, 204, 204);
  border-left: none;
  border-right: none;
  text-indent: 25px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  margin-top: -1px;
  margin-bottom: 20px;
}
.el-input__inner[type='number'] {
  padding-right: 0;
}
.Chatbox img {
  max-width: 300px !important;
  cursor: pointer;
}
.vue-directive-image-previewer {
  z-index: 9999999;
}
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #d4d4d4;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #999797;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #7f7f7f;
  cursor: pointer;
}

/**::-webkit-scrollbar-corner{
  background: #01055c;
}*/
.el-image-viewer__mask {
  /*background-color: transparent;*/
}
.el-image-viewer__wrapper {
  width: 34%;
}
.el-image .el-icon-circle-close {
  border-radius: 50%;
  background-color: #606266;
  color: #fff;
}
.el-image__preview {
  display: none;
}
.demo-image__preview,
.el-image__error {
  height: 0;
}
.formStyle {
  overflow: hidden;
  padding-bottom: 15px;
}

.content {
  padding-bottom: 20px;
}
.page-btns {
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
}
.dividerDom {
  padding-top: 10px;
}
.form-btns {
  padding-left: 30px;
}
.bottom-btns {
  background: #fff;
  position: fixed;
  right: 12px;
  bottom: 0;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 5;
}
.pages {
  padding: 20px;
  box-sizing: border-box;
}
</style>
