var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "item" }, [
      _vm.label
        ? _c(
            "div",
            {
              staticClass: "label",
              style: _vm.labelWidth
                ? "min-width:" + _vm.labelWidth
                : "min-width:130px;"
            },
            [
              _c(
                "span",
                { class: _vm.required ? "is-required" : "" },
                [
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                  _vm.label && _vm.label != " " ? [_vm._v("：")] : _vm._e()
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "item-content" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }