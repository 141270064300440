<template>
  <div class="disused">
    <div class="head">
      <el-form :model="queryForm">
        <el-form-item label="微信号：">
          <el-input
            v-model="queryForm.wechatNum"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号：">
          <el-input
            v-model="queryForm.bindPhone"
            oninput="this.value = this.value.replace(/[^\d.]/g,'');"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="使用人：">
          <el-select
            v-model="userIds"
            multiple
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in userList"
              :label="item.personRealName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信号属性：">
          <el-select
            v-model="attributeIds"
            multiple
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in attrPages"
              :label="item.attributeName"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button @click="tableSearch" type="primary">查询</el-button>
        <export-excel
          v-if="$store.state.common.hasPermission.includes('导出')"
          :requestParams="queryForm"
          action="/manage/wechat/assets/qryPage"
          :columns="columns"
          filename="资产列表"
        ></export-excel>
      </el-form>
    </div>
    <div class="content">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :data="tableData"
        border
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <template v-for="(item, index) in columns">
          <el-table-column
            v-if="item.prop != 'loginPassword'"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :formatter="item.formatter"
          ></el-table-column>
        </template>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="$store.state.common.hasPermission.includes('删除')"
              @click="
                dataId = scope.row.id
                deleteObject = true
              "
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div style="text-align: center; margin: 10px">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="queryForm.pageSize"
          :current-page="queryForm.pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--    删除-->
    <el-dialog title="温馨提示" :visible.sync="deleteObject" width="25%">
      <div>确定要删除该微信号吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteObject = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="removeObject()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deleteInfo,
  qryPage,
  saveInfo,
  scrapObj,
  recoveryObj,
  changeObj
} from '@/api/assetsList'
import { attrPage, ExceptionalPage } from '@/api/labelManagement'
import { queryList } from '@/api/organization'

let columns = [
  {
    label: '微信号',
    prop: 'wechatNum'
  },
  {
    label: '微信好友数',
    prop: 'friendNum'
  },
  {
    label: '报废前好友数',
    prop: 'scrapFriendNum'
  },
  {
    label: '微信状态',
    prop: 'wechatStatusName'
  },
  {
    label: '使用人',
    prop: 'userName'
  },
  {
    label: '所在部门',
    prop: 'deptName'
  },
  {
    label: '微信昵称',
    prop: 'nickName'
  },
  {
    label: '登录方式',
    prop: 'loginMethod'
  },
  {
    label: '登录密码',
    prop: 'loginPassword'
  },
  {
    label: '实名认证信息',
    prop: 'realName'
  },
  {
    label: '开户行',
    prop: 'bankName'
  },
  {
    label: '银行卡号',
    prop: 'bankNo'
  },
  {
    label: '可修改微信号时间',
    prop: 'editWechatDate'
  },
  {
    label: '绑定手机号',
    prop: 'bindPhone'
  },
  {
    label: '手机号使用人',
    prop: 'phoneUsingName'
  },
  {
    label: '微信号属性',
    prop: 'attributeName'
  },
  {
    label: '报废时间',
    prop: 'scrapTime'
  },
  {
    label: '报废原因',
    prop: 'scrapReason'
  },
  {
    label: '报废人',
    prop: 'scrapPersonName'
  }
]
export default {
  data() {
    return {
      userList: [],
      attrPages: [],
      userIds: [],
      attributeIds: [],
      columns: columns,
      dialogVisible: false,
      InfoTitle: '',
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        wechatNum: '',
        bindPhone: '',
        userIds: '',
        attributeIds: ''
      },
      total: 0,
      tableData: [],
      abnormalDialog: false,
      abnormalForm: {},
      deleteObject: false,
      dataId: null,
      loading: false
    }
  },
  created() {
    this.$store.dispatch('findRoleMenuButtonFn', 'assetsListDisused')
    // 微信号属性
    attrPage({
      pageNum: 1,
      deletedFlag: 2,
      pageSize: 9999999
    }).then((data) => {
      this.attrPages = data.data.list
    })
    // 用户列表
    queryList({
      pageNum: 1,
      pageSize: 9999999
    }).then((data) => {
      this.userList = data.data.list
    })
    this.doGetList()
  },
  methods: {
    // 分页跳转
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.doGetList()
    },
    tableSearch() {
      this.queryForm.pageNum = 1
      this.doGetList()
    },
    doGetList() {
      this.loading = true
      this.queryForm.wechatStatus = 2
      this.queryForm.userIds = this.userIds.join(',')
      this.queryForm.attributeIds = this.attributeIds.join(',')
      qryPage(this.queryForm).then((data) => {
        this.tableData = data.data.list
        this.queryForm.pageNum = data.data.current
        this.total = data.data.total
        this.loading = false
      })
    },
    removeObject() {
      this.loading = true
      deleteInfo({ id: this.dataId }).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.dataId = ''
          this.deleteObject = false
          this.doGetList()
        } else {
          this.$message.error(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
.disused {
  .el-form-item {
    margin-right: 20px;
  }

  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }

  .el-form-item__content {
    margin-left: 0;
    width: 200px;
  }
}
</style>
<style lang="scss" scoped>
.head,
.content {
  overflow: hidden;
  margin: 30px;
}

::v-deep .table-input .el-input__inner {
  text-align: center;
}

::v-deep .abnormal-dialog {
  .el-form-item__label {
    width: 120px;
  }
  .el-select {
    width: 200px;
  }

  .el-input__inner {
    width: 200px;
  }

  .el-textarea {
    width: 540px;
  }
}
</style>
