
import request from "@/utils/request";
//模糊查询人员
export function findUserList(data) {
  return request({
    url: '/manage/statistics/findUserList',
    method: 'post',
    data
  })
}

// 查询课程顾问
export function queryConsultantList(data) {
  return request({
    url: '/manage/consultant/queryConsultantList',
    method: 'post',
    data
  })
}


// (下拉列表)根据部门获取课程顾问
export function queryPersonPull(data) {
  return request({
    url: '/manage/person/queryPersonPull',
    method: 'post',
    data
  })
}

// 单日统计-新增好友数
export function statisticsAddFriend(data) {
  return request({
    url: '/manage/wechat/data/statisticsAddFriend',
    method: 'post',
    data
  })
}
// 资产列表
export function queryAssets(data) {
  return request({
    url: '/manage/wechat/assets/queryList',
    method: 'post',
    data
  })
}
// 单日统计-删除好友数
export function statisticsDelFriend(data) {
  return request({
    url: '/manage/wechat/data/statisticsDelFriend',
    method: 'post',
    data
  })
}
// 单日统计-好友总数
export function statisticsFriendTotal(data) {
  return request({
    url: '/manage/wechat/data/statisticsFriendTotal',
    method: 'post',
    data
  })
}
// 单日统计-好友总数
export function totalStatistics(data) {
  return request({
    url: '/manage/wechat/data/totalStatistics',
    method: 'post',
    data
  })
}
// 渠道统计
export function channelStatistics(data) {
  return request({
    url: '/manage/wechat/data/channelStatistics',
    method: 'post',
    data
  })
}
// 每日统计
export function everyDayFriendNum(data) {
  return request({
    url: '/manage/wechat/data/everyDayFriendNum',
    method: 'post',
    data
  })
}
// 月度统计、对比统计
export function monthContrastStatistics(data) {
  return request({
    url: '/manage/wechat/data/monthContrastStatistics',
    method: 'post',
    data
  })
}
// 根据部门查所有员工列表 部门多选
export function queryStaffList(data) {
  return request({
    url: '/manage/person/queryStaffList',
    method: 'post',
    data
  })
}