<template>
  <div style="position: relative; padding-top: 20px">
    <div
      class="echart"
      :id="id"
      :style="{ width: '100%', height: '500px' }"
    ></div>
    <div class="icon-open">
      <el-switch
        v-model="ifShowLabel"
        active-text="显示数值"
        @change="handleSwitch"
      >
      </el-switch>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: ['id', 'value'],
  data() {
    return {
      ifShowLabel: true,
      title: '',
      legend: [],
      xData: [],
      yData: []
    }
  },
  methods: {
    // 是否显示数值
    handleSwitch() {
      this.initChart(this.title, this.legend, this.xData, this.yData)
    },
    initChart(title, legend, xData, yData) {
      this.title = title
      this.legend = legend
      this.xData = xData
      this.yData = yData

      let getchart = echarts.init(document.getElementById(this.id))
      var option = {
        title: {
          text: title,
          top: 'bottom',
          left: 'center'
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1]
          },
          {
            type: 'inside',
            realtime: true,
            start: 30,
            end: 70,
            xAxisIndex: [0, 1]
          }
        ],
        tooltip: {
          trigger: 'axis',
          className: 'custom-tooltip',
          formatter: function (params) {
            let res = ''
            params.forEach((item, index) => {
              res += `<div class="tooltip-item">
              <div class='item-icon' style="width: 10px;height: 10px;background-color: ${item.color};border-radius: 50%;margin: 0 5px 0 0;color:rgba(0,0,0,0)"> 
                </div>
              <div style="margin: 0 10px 0 0;">${item.seriesName}</div>
              <div>${item.value}</div>
              </div>`
            })

            // 返回数据
            return res
          }
        },
        legend: {
          data: legend
        },
        grid: {
          //调整图表上下左右位置
          left: '5%',
          right: '5%',
          bottom: '11%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
          axisLabel: {
            interval: 0,
            rotate: 40
          }
        },
        yAxis: {
          type: 'value'
        },
        label: {
          show: this.ifShowLabel
        },
        series: yData
      }
      getchart.clear()
      getchart.setOption(option)
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .custom-tooltip {
  min-width: 300px;
  max-width: 800px;
  display: none;
  padding: 20px 20px 0px 0 !important;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.98) !important;
  border-radius: 6px !important;
  box-sizing: border-box;
  .tooltip-item {
    min-width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: start;
    float: left;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding-left: 20px;
    .item-icon {
      background: #409eff;
    }
  }
}
.icon-open {
  position: absolute;
  right: 10px;
  top: -20px;
  font-size: 26px;
  cursor: pointer;
}
</style>
