import request from '@/utils/request'
/*--------部门管理--------*/
// 获取部门树
export function getDeptTree() {
  return request({
    url: '/manage/sys/dept/deptTree',
    method: 'get',
  })
}

// 获取指定部门的子级列表
export function findDeptList(data) {
  return request({
    url: '/manage/sys/dept/findDeptList',
    method: 'post',
    data
  })
}

// 编辑部门
export function editDept(data) {
  return request({
    url: '/manage/sys/dept/editDept',
    method: 'post',
    data
  })
}

// 新增部门
export function insertDept(data) {
  return request({
    url: '/manage/sys/dept/insertDept',
    method: 'post',
    data
  })
}
// 移除部门
export function removeDept(data) {
  return request({
    url: '/manage/sys/dept/removeDept',
    method: 'post',
    data
  })
}

/*--------岗位管理--------*/

// 获取岗位列表
export function findPostList(data) {
  return request({
    url: '/manage/sys/post/findPostList',
    method: 'post',
    data
  })
}

// 编辑岗位
export function editPost(data) {
  return request({
    url: '/manage/sys/post/editPost',
    method: 'post',
    data
  })
}

// 新增岗位
export function insertPost(data) {
  return request({
    url: '/manage/sys/post/insertPost',
    method: 'post',
    data
  })
}

// 查询部门主管岗位
export function queryDirector(data) {
  return request({
    url: '/manage/sys/post/queryDirector',
    method: 'post',
    data
  })
}

// 设置为主管
export function editDirector(data) {
  return request({
    url: '/manage/sys/post/editDirector',
    method: 'post',
    data
  })
}

// 岗位移除
export function removePost(data) {
  return request({
    url: '/manage/sys/post/removePost',
    method: 'post',
    data
  })
}

/*--------员工管理--------*/

// 获取员工列表
export function queryList(data) {
  return request({
    url: '/manage/person/queryList',
    method: 'post',
    data
  })
}

// 添加员工
export function addUser(data) {
  return request({
    url: '/manage/person/addUser',
    method: 'post',
    data
  })
}

// 编辑员工
export function editUser(data) {
  return request({
    url: '/manage/person/editUser',
    method: 'post',
    data
  })
}

// 分配角色
export function addPersonRole(data) {
  return request({
    url: '/manage/person/addAssetsPersonRole',
    method: 'post',
    data
  })
}

// 交换数据
export function Handover(data) {
  return request({
    url: '/manage/person/Handover',
    method: 'post',
    data
  })
}

// 删除员工
export function delUser(data) {
  return request({
    url: '/manage/person/delUser',
    method: 'post',
    data
  })
}
// 根据岗位id查询上级负责人列表
export function queryParentPerson(data) {
  return request({
    url: '/manage/person/queryParentPerson',
    method: 'post',
    data
  })
}

/*--------功能权限管理--------*/

// 获取角色列表
export function rolePage(data) {
  return request({
    url: '/manage/assets/role/rolePage',
    method: 'post',
    data
  })
}

// 添加角色
export function roleAdd(data) {
  return request({
    url: '/manage/assets/role/roleAdd',
    method: 'post',
    data
  })
}

// 角色权限列表保存
export function saveRoleMenu(data) {
  return request({
    url: '/manage/assets/role/saveRoleMenu',
    method: 'post',
    data
  })
}

// 删除角色
export function deleteRole(data) {
  return request({
    url: '/manage/assets/role/deleteRole',
    method: 'post',
    data
  })
}

// 角色编辑
export function editRole(data) {
  return request({
    url: '/manage/assets/role/editRole',
    method: 'post',
    data
  })
}
// 角色菜单展示
export function tmenuTree(data) {
  return request({
    url: '/manage/assets/menu/tmenuTree',
    method: 'post',
    data
  })
}
// 用户管理-添加课程顾问
export function insertConsultant(data) {
  return request({
    url: '/manage/consultant/insertConsultant',
    method: 'post',
    data
  })
}
// 用户管理-删除课程顾问
export function deletedConsultant(data) {
  return request({
    url: '/manage/consultant/deletedConsultant/' + data,
    method: 'post',
  })
}


