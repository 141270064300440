var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "content table-components" },
      [
        _c(
          "el-table",
          {
            attrs: {
              "header-cell-style": { textAlign: "center" },
              "cell-style": { textAlign: "center" },
              data: _vm.data,
              border: ""
            },
            on: {
              "sort-change": _vm.sortChange,
              "selection-change": _vm.handleSelectionChange
            }
          },
          [
            _vm.showSelection
              ? _c("el-table-column", {
                  attrs: { type: "selection", width: "55" }
                })
              : _vm._e(),
            _vm.showIndex
              ? _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" }
                })
              : _vm._e(),
            _vm._l(_vm.columns, function(item, index) {
              return [
                !item.slotName &&
                item.prop != "index" &&
                item.type != "img" &&
                item.type != "qrCode"
                  ? _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width ? item.width : "200",
                        fixed: item.fixed,
                        formatter: item.formatter,
                        sortable: item.sortable ? item.sortable : false,
                        "show-overflow-tooltip": item.showOverflowTooltip
                          ? item.showOverflowTooltip
                          : true
                      }
                    })
                  : _vm._e(),
                item.type == "img"
                  ? _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width ? item.width : 100,
                        fixed: item.fixed
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center"
                                    }
                                  },
                                  [
                                    scope.row[item.prop]
                                      ? _c("el-image", {
                                          staticStyle: {
                                            "max-width": "100px",
                                            height: "40px"
                                          },
                                          attrs: {
                                            src: scope.row[item.prop],
                                            fit: "contain",
                                            "preview-src-list": [
                                              scope.row[item.prop]
                                            ]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e(),
                item.type == "qrCode"
                  ? _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width ? item.width : 100,
                        fixed: item.fixed
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row[item.prop]
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qr-container",
                                            on: { click: _vm.toggleQrZoom }
                                          },
                                          [
                                            item.url
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("vue-qr", {
                                                      staticStyle: {
                                                        display: "inline-block"
                                                      },
                                                      attrs: {
                                                        text:
                                                          item.url +
                                                          scope.row[item.prop],
                                                        size: 40
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("vue-qr", {
                                                      staticStyle: {
                                                        display: "inline-block"
                                                      },
                                                      attrs: {
                                                        text:
                                                          scope.row[item.prop],
                                                        size: 40
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                          ]
                                        ),
                                        _vm.isQrZoomed
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "zoomed-qr-container"
                                              },
                                              [
                                                _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-close",
                                                    on: {
                                                      click: _vm.toggleQrZoom
                                                    }
                                                  })
                                                ]),
                                                item.url
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("vue-qr", {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block"
                                                          },
                                                          attrs: {
                                                            text:
                                                              item.url +
                                                              scope.row[
                                                                item.prop
                                                              ],
                                                            size: 250
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c("vue-qr", {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block"
                                                          },
                                                          attrs: {
                                                            text:
                                                              scope.row[
                                                                item.prop
                                                              ],
                                                            size: 250
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e(),
                item.slotName ? _vm._t(item.slotName) : _vm._e()
              ]
            }),
            _vm._t("operate")
          ],
          2
        ),
        _vm.pageSize
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", margin: "20px 0 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": _vm.pageSize,
                    "current-page": _vm.pageNum,
                    total: _vm.total
                  },
                  on: { "current-change": _vm.handleCurrentChange }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }