import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

let baseURL = ''
if (process.env.NODE_ENV == 'development') {
  // baseURL = 'http://192.168.25.186:8081'
  baseURL = process.env.VUE_APP_BASE_API
  // baseURL = 'https://portal.qichengzaixian.com:8443'
} else {
  baseURL = process.env.VUE_APP_BASE_API
}

// process.env.VUE_APP_BASE_API
// const baseURL ='http://192.168.25.14:8081'
// const baseURL = 'http://192.168.25.121:8081'

// websocket
export function getbaseurl(data) {
  return process.env.VUE_APP_WEBSOCKET + '?userId=' + data + '_pc'
}

// 资料下载查看
export function studentBaseurl() {
  return process.env.VUE_APP_STUDENTBASEURL
}

// 课堂直播配置
export function roomConfig() {
  return {
    appKey: process.env.VUE_APP_APPKEY,
    appId: process.env.VUE_APP_APPID
  }
}


// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 500000 // request timeout
})

//图片上传
const imgURL = baseURL + '/manage/file/uploadAli'
window.sessionStorage.setItem("imgURL", imgURL);

//线索管理-批量导入上传
const studentDataImport = baseURL + '/manage/student/studentDataImport'
window.sessionStorage.setItem("studentDataImport", studentDataImport);


// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // if the custom code is not 20000, it is judged as an error.
    /* if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {*/
    const res = response.data
    if (res.status === 401) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      store.dispatch('user/resetToken').then(() => {
        location.reload()
      })
    } else if (res.status === 444) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // location.reload()

    }
    else {

      return res
    }
    // }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return error
    // return Promise.reject(error)
  }
)

export default service
export { baseURL }
