var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _vm._m(0),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar")],
            1
          ),
          _c("app-main")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { height: "60px", "background-color": "#0e81c1" } },
      [
        _c("img", {
          staticStyle: {
            width: "200px",
            "margin-top": "12px",
            "margin-left": "20px"
          },
          attrs: { src: require("@/assets/login/logo2.png"), alt: "" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }