import request from '@/utils/request'

// 列表查询
export function getLogList(data) {
  return request({
    url: '/manage/sys/logs/pageLogs',
    method: 'post',
    data
  })
}

//获得模块类型
export function searchModule() {
  return request({
    url: '/manage/sys/logs/logModuleFind',
    method: 'post'
  })
}
//获得模块类型
export function exportLogs(data) {
  return request({
    url: '/manage/sys/logs/exportLogs',
    method: 'post',
    data
  })
}
/*-------学生端操作日志-------*/
//学员端日志查询
export function queryStuLogs(data) {
  return request({
    url: '/manage/sys/logs/queryStuLogs',
    method: 'post',
    data
  })
}
//学员端操作模块名称
export function findLogType(data) {
  return request({
    url: '/manage/sys/logs/findLogType',
    method: 'post',
    data
  })
}
// 跟进记录查询
export function queryCrmFollowup(data) {
  return request({
    url: '/manage/crmFollowup/pageQuery',
    method: 'post',
    data
  })
}
// 指定时间段内跟进记录的数据统计
export function queryTotalCrmStatistics(data) {
  return request({
    url: '/manage/crmStatistics/queryTotal',
    method: 'post',
    data
  })
}
// 通过记录列表分页
export function callPageQuery(data) {
  return request({
    url: '/manage/call/pageQuery',
    method: 'post',
    data
  })
}
// 通过记录详情
export function callQueryObjectInfo(data) {
  return request({
    url: '/manage/call/queryObjectInfo',
    method: 'post',
    data
  })
}
// 呼叫状态列表
export function ringCauseQuery(data) {
  return request({
    url: '/manage/call/ringCauseQuery',
    method: 'post',
    data
  })
}