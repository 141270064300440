<template>
  <div>
    <!-- ********
    *
    *
    *
    *
    *
    不继续维护  转移至@/components/table-components/index.vue
    *
    *
    *
    *
    *
    *
    ****** -->
    <!-- max-height="580" -->
    <div class="content table-components">
      <el-table
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        :data="data"
        @sort-change="sortChange"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="showSelection" type="selection" width="55">
        </el-table-column>
        <el-table-column
          v-if="showIndex"
          type="index"
          label="序号"
          width="80"
        ></el-table-column>
        <template v-for="(item, index) in columns">
          <el-table-column
            v-if="
              !item.slotName &&
              item.prop != 'index' &&
              item.type != 'img' &&
              item.type != 'qrCode'
            "
            :label="item.label"
            :key="index"
            :prop="item.prop"
            :width="item.width ? item.width : '200'"
            :fixed="item.fixed"
            :formatter="item.formatter"
            :sortable="item.sortable ? item.sortable : false"
            :show-overflow-tooltip="
              item.showOverflowTooltip ? item.showOverflowTooltip : true
            "
          >
          </el-table-column>
          <el-table-column
            v-if="item.type == 'img'"
            :label="item.label"
            :key="index"
            :prop="item.prop"
            :width="item.width ? item.width : 100"
            :fixed="item.fixed"
          >
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-image
                  v-if="scope.row[item.prop]"
                  style="max-width: 100px; height: 40px"
                  :src="scope.row[item.prop]"
                  fit="contain"
                  :preview-src-list="[scope.row[item.prop]]"
                >
                </el-image>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="item.type == 'qrCode'"
            :label="item.label"
            :key="index"
            :prop="item.prop"
            :width="item.width ? item.width : 100"
            :fixed="item.fixed"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row[item.prop]"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div class="qr-container" @click="toggleQrZoom">
                  <div v-if="item.url">
                    <vue-qr
                      style="display: inline-block"
                      :text="item.url + scope.row[item.prop]"
                      :size="40"
                    ></vue-qr>
                  </div>
                  <div v-else>
                    <vue-qr
                      style="display: inline-block"
                      :text="scope.row[item.prop]"
                      :size="40"
                    ></vue-qr>
                  </div>
                </div>

                <div v-if="isQrZoomed" class="zoomed-qr-container">
                  <div>
                    <i @click="toggleQrZoom" class="el-icon-circle-close"></i>
                  </div>
                  <div v-if="item.url">
                    <vue-qr
                      style="display: inline-block"
                      :text="item.url + scope.row[item.prop]"
                      :size="250"
                    ></vue-qr>
                  </div>
                  <div v-else>
                    <vue-qr
                      style="display: inline-block"
                      :text="scope.row[item.prop]"
                      :size="250"
                    ></vue-qr>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <slot :name="item.slotName" v-if="item.slotName"></slot>
        </template>
        <slot name="operate"></slot>
      </el-table>
      <div style="text-align: center; margin: 20px 0 0" v-if="pageSize">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNum"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/******
 * 表头columns内的字段说明
 * @param label {String} 表头名称
 * @param prop  {String} 对应数据字段
 * @param fixed {Boolean|String} 是否固定列
 * @param slotName {String} 自定义插槽名称
 * @param width {Number} 列宽度
 * @param type {String} 列类型 （目前只支持img）
 * @format {Function} 格式化方法
 * @showOverflowTooltip {Boolean} 是否显示tooltip
 * *******/
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  props: [
    'columns',
    'data',
    'pageSize',
    'pageNum',
    'total',
    'showIndex', // 是否显示序号
    'showSelection' // 是否显示复选框按钮
  ],
  data() {
    return {
      isQrZoomed: false
    }
  },
  methods: {
    handleCurrentChange(pageNum) {
      this.$emit('get-list', pageNum)
    },
    // 当选择项发生变化时
    handleSelectionChange(selectedRowList) {
      this.$emit('selection-change', selectedRowList)
    },
    toggleQrZoom() {
      this.isQrZoomed = !this.isQrZoomed
    },
    // 当表格的排序条件发生变化时触发
    sortChange(column, prop, order) {
      this.$emit('sort-change', column, prop, order)
    }
  }
}
</script>
<style lang="scss" scoped>
.table-cell-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
::v-deep .el-table td,
::v-deep .el-table th {
  padding: 9px 0;
}
.table-components {
  ::v-deep .el-image__preview {
    display: block;
  }
  ::v-deep .el-image-viewer__mask {
    position: fixed;
  }
  ::v-deep .el-image-viewer__wrapper {
    width: 100%;
  }
}
.qr-container {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.zoomed-qr-container {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  z-index: 1000;
  .el-icon-circle-close {
    float: right;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
