import request from '@/utils/request'

// 心跳
export function tokenEffectiveQry(data) {
  return request({
    url: '/manage/login/tokenEffectiveQry',
    method: 'post',
    data
  })
}
/**-------敏感词管理------**/
// 列表查询
export function querySensitiveWordList(data) {
  return request({
    url: '/manage/sys/dictionary/pageSensitiveWord',
    method: 'post',
    data
  })
}

// 敏感词添加
export function insertSensitiveWord(data) {
  return request({
    url: '/manage/sys/dictionary/addSensitiveWord',
    method: 'post',
    data
  })
}

// 查看详情
export function querySensitiveWord(data) {
  return request({
    url: '/manage/sys/dictionary/querySensitiveWord/' + data,
    method: 'post',
  })
}

// 敏感词编辑
export function editSensitiveWord(data) {
  return request({
    url: '/manage/sys/dictionary/editSensitiveWord',
    method: 'post',
    data
  })
}

// 敏感词移除
export function deleteSensitiveWord(data) {
  return request({
    url: '/manage/sys/dictionary/removeSensitiveWord',
    method: 'post',
    data
  })
}

/**-------用户标签管理------**/
// 列表查询
export function queryLabelList(data) {
  return request({
    url: '/manage/sys/dictionary/pageLabel',
    method: 'post',
    data
  })
}
// 标签添加
export function insertLabel(data) {
  return request({
    url: '/manage/sys/dictionary/insertLabel',
    method: 'post',
    data
  })
}
// 标签详情
export function queryLabel(data) {
  return request({
    url: '/manage/sys/dictionary/queryLabel/' + data,
    method: 'post',
  })
}
// 标签编辑
export function editLabel(data) {
  return request({
    url: '/manage/sys/dictionary/editLabel',
    method: 'post',
    data
  })
}
// 移除
export function deleteLabel(data) {
  return request({
    url: '/manage/sys/dictionary/removeLabel',
    method: 'post',
    data
  })
}

/**-------用户渠道管理------**/
// 列表查询
export function queryChannelList(data) {
  return request({
    url: '/manage/sys/dictionary/pageChannel',
    method: 'post',
    data
  })
}
//添加
export function insertChannel(data) {
  return request({
    url: '/manage/sys/dictionary/addChannel',
    method: 'post',
    data
  })
}
//详情查询
export function queryChannel(data) {
  return request({
    url: '/manage/sys/dictionary/queryChannel/' + data,
    method: 'post',
  })
}
//编辑
export function editChannel(data) {
  return request({
    url: '/manage/sys/dictionary/editChannel',
    method: 'post',
    data
  })
}
//移除
export function deleteChannel(data) {
  return request({
    url: '/manage/sys/dictionary/deletedChannel/' + data,
    method: 'post',
  })
}

/**-------关键字管理------**/
//列表查询
export function queryKeywordList(data) {
  return request({
    url: '/manage/sys/dictionary/pageKeyWord',
    method: 'post',
    data
  })
}
//添加
export function insertKeyword(data) {
  return request({
    url: '/manage/sys/dictionary/addKeyWord',
    method: 'post',
    data
  })
}
//详情查询
export function queryKeyword(data) {
  return request({
    url: '/manage/sys/dictionary/queryKeyWord/' + data,
    method: 'post',
  })
}
//编辑
export function editKeyword(data) {
  return request({
    url: '/manage/sys/dictionary/editKeyWord',
    method: 'post',
    data
  })
}
//移除
export function deleteKeyword(data) {
  return request({
    url: '/manage/sys/dictionary/removeKeyWord',
    method: 'post',
    data
  })
}

/**-------假期管理------**/
//假期查询

export function queryAll(data) {
  return request({
    url: '/manage/sys/holiday/queryAll',
    method: 'post',
    data
  })
}

//假期保存
export function saveHoliday(data) {
  return request({
    url: '/manage/sys/holiday/saveHoliday',
    method: 'post',
    data
  })
}
/**-------专业设置-----**/
//问答专业管理 -列表
export function pageMajor(data) {
  return request({
    url: '/manage/sys/dictionary/pageMajor',
    method: 'post',
    data
  })
}
//问答专业管理-上下移动
export function upDownMajor(data) {
  return request({
    url: '/manage/sys/dictionary/upDownMajor',
    method: 'post',
    data
  })
}
//问答专业管理-删除
export function removeMajor(data) {
  return request({
    url: '/manage/sys/dictionary/removeMajor',
    method: 'post',
    data
  })
}
//问答专业管理-新增
export function insertMajor(data) {
  return request({
    url: '/manage/sys/dictionary/insertMajor',
    method: 'post',
    data
  })
}
//问答专业管理-编辑
export function editMajor(data) {
  return request({
    url: '/manage/sys/dictionary/editMajor',
    method: 'post',
    data
  })
}
//问答专业管理-详情
export function queryMajor(data) {
  return request({
    url: '/manage/dictionary/queryMajor/' + data,
    method: 'post',
  })
}
//问答专业管理-详情
export function queryMajorTreeAll() {
  return request({
    url: '/manage/sys/dictionary/queryMajorTreeAll',
    method: 'post',
  })
}

/**-------通用设置------**/
//通用字典信息
export function dictionaryList() {
  return request({
    url: '/manage/sys/dictionary/pageList',
    method: 'post'
  })
}

//字典数据批量保存
export function editBatch(data) {
  return request({
    url: '/manage/sys/dictionary/editBatch',
    method: 'post',
    data
  })
}

//分享页面跳转路径查询
export function queryJumpPath(data) {
  return request({
    url: '/manage/sys/dictionary/queryJumpPath',
    method: 'post',
    data
  })
}

//渠道分享链接
export function channelShareLinks(data) {
  return request({
    url: '/manage/sys/dictionary/channelShareLinks',
    method: 'post',
    data
  })
}
// 渠道上移下移
export function upDownObject(data) {
  return request({
    url: '/manage/sys/dictionary/channelUpOrDown',
    method: 'post',
    data
  })
}
// 红包序列号分页查询
export function serialNumQuery(data) {
  return request({
    url: '/manage/sys/dictionary/serialNumQuery',
    method: 'post',
    data
  })
}
// 导入红包序列号
export function serialNumImport(data) {
  return request({
    url: '/manage/sys/dictionary/serialNumImport',
    method: 'post',
    data
  })
}
// 落地页添加-单个
export function addObject(data) {
  return request({
    url: '/manage/sys/landingPage/addObject',
    method: 'post',
    data
  })
}
// 查询落地页信息
export function queryPageInfo(data) {
  return request({
    url: '/manage/sys/landingPage/queryInfo',
    method: 'post',
    data
  })
}
// 落地页编辑
export function editObject(data) {
  return request({
    url: '/manage/sys/landingPage/editObject',
    method: 'post',
    data
  })
}
// 核心词查询
export function pageCoreWords(data) {
  return request({
    url: '/manage/sys/dictionary/pageCoreWords',
    method: 'post',
    data
  })
}
// 核心词新增
export function addCoreWords(data) {
  return request({
    url: '/manage/sys/dictionary/addCoreWords',
    method: 'post',
    data
  })
}
// 核心词修改
export function editCoreWords(data) {
  return request({
    url: '/manage/sys/dictionary/editCoreWords',
    method: 'post',
    data
  })
}
// 核心词删除
export function removeCoreWords(data) {
  return request({
    url: '/manage/sys/dictionary/removeCoreWords',
    method: 'post',
    data
  })
}
// 部门及其下属
export function queryPersonDeptList(data) {
  return request({
    url: '/manage/sys/dept/queryPersonDeptList',
    method: 'post',
    data
  })
}
// 通用设置-字典数据删除
export function removeDictionary(data) {
  return request({
    url: '/manage/sys/dictionary/removeDictionary',
    method: 'post',
    data
  })
}
// 运营人员list
export function operateListQuery(data) {
  return request({
    url: '/manage/operate/operateListQuery',
    method: 'post',
    data
  })
}
// 用户渠道-小程序分享页面查询
export function pageAppletShare(data) {
  return request({
    url: '/manage/sys/dictionary/pageAppletShare',
    method: 'post',
    data
  })
}
// 用户渠道-小程序分享页面添加
export function addAppletShare(data) {
  return request({
    url: '/manage/sys/dictionary/addAppletShare',
    method: 'post',
    data
  })
}
// 用户渠道-小程序分享页面添加
export function editAppletShare(data) {
  return request({
    url: '/manage/sys/dictionary/editAppletShare',
    method: 'post',
    data
  })
}
// 用户渠道-小程序分享页面移除
export function removeAppletShare(data) {
  return request({
    url: '/manage/sys/dictionary/removeAppletShare',
    method: 'post',
    data
  })
}
// 支付方式
export function payModeQuery(data) {
  return request({
    url: '/manage/order/payModeQuery',
    method: 'post',
    data
  })
}
// 查询价格是否设置显示
export function queryGuidedPayment(data) {
  return request({
    url: '/manage/sys/dictionary/queryGuidedPayment',
    method: 'post',
    data
  })
}
// 查询回调地址
export function queryCallback(data) {
  return request({
    url: '/manage/dyQualification/queryCallback',
    method: 'post',
    data
  })
}
// 回调地址设置
export function settingCallback(data) {
  return request({
    url: '/manage/dyQualification/settingCallback',
    method: 'post',
    data
  })
}
// 更改是否显示价格
export function editGuidedPayment(data) {
  return request({
    url: '/manage/sys/dictionary/editGuidedPayment',
    method: 'post',
    data
  })
}
// 查询线索设置信息
export function cluePageQuery(data) {
  return request({
    url: '/manage/sys/dictionaryPerson/pageQuery',
    method: 'post',
    data
  })
}
// 修改线索设置信息
export function clueEditDictionary(data) {
  return request({
    url: '/manage/sys/dictionaryPerson/editDictionary',
    method: 'post',
    data
  })
}
// 教师标签列表查询
export function labelPage(data) {
  return request({
    url: '/manage/person/labelPage',
    method: 'post',
    data
  })
}
// 教师标签 新增
export function saveLabel(data) {
  return request({
    url: '/manage/person/saveLabel',
    method: 'post',
    data
  })
}
// 教师标签 编辑
export function personEditLabel(data) {
  return request({
    url: '/manage/person/editLabel',
    method: 'post',
    data
  })
}
// 教师标签 删除
export function removeLabel(data) {
  return request({
    url: '/manage/person/removeLabel',
    method: 'post',
    data
  })
}
// 学员昵称词库 列表
export function pageNounWords(data) {
  return request({
    url: '/manage/sys/dictionary/pageNounWords',
    method: 'post',
    data
  })
}
// 学员昵称词库 添加
export function addNicknWords(data) {
  return request({
    url: '/manage/sys/dictionary/addNicknWords',
    method: 'post',
    data
  })
}
// 学员昵称词库 修改
export function editNickWords(data) {
  return request({
    url: '/manage/sys/dictionary/editNickWords',
    method: 'post',
    data
  })
}
// 学员昵称词库 删除
export function removeNickWords(data) {
  return request({
    url: '/manage/sys/dictionary/removeNickWords',
    method: 'post',
    data
  })
}
// 查询用户每天资料下载次数
export function queryDownNum(data) {
  return request({
    url: '/manage/dataContent/queryDownNum',
    method: 'post',
    data
  })
}
// 防盗链开关查询
export function queryVideoAntiTheft(data) {
  return request({
    url: '/manage/sys/dictionary/queryVideoAntiTheft',
    method: 'post',
    data
  })
}

// 修改用户每天资料下载次数
export function editDownNum(data) {
  return request({
    url: '/manage/dataContent/editDownNum',
    method: 'post',
    data
  })
}
// 抖音资质分页查询
export function dyQualificationList(data) {
  return request({
    url: '/manage/dyQualification/newPageQuery',
    method: 'post',
    data
  })
}
// 抖音资质分页查询
export function removeDyQualification(data) {
  return request({
    url: '/manage/dyQualification/removeObject',
    method: 'post',
    data
  })
}
// 抖音资质添加、修改
export function saveDyQualification(data) {
  return request({
    url: '/manage/dyQualification/newSaveObject',
    method: 'post',
    data
  })
}

// 抖音资质详情
export function queryDyQualificationInfo(data) {
  return request({
    url: `/manage/dyQualification/newQueryDetails/${data}`,
    method: 'post',
  })
}
// 大转盘抽奖-活动类型
export function queryActivityType() {
  return request({
    url: '/manage/sys/prize/queryActivityType',
    method: 'post',
  })
}
// 大转盘抽奖-活动查询
export function prizeListQuery() {
  return request({
    url: '/manage/draw/activityPage',
    method: 'post',
  })
}
// 大转盘抽奖-活动删除
export function prizeRemoveObject(data) {
  return request({
    url: '/manage/sys/prize/removeObject',
    method: 'post',
    data
  })
}
// 大转盘抽奖-活动保存
export function prizeSaveObject(data) {
  return request({
    url: '/manage/draw/saveActivity',
    method: 'post',
    data
  })
}
// 大转盘抽奖-奖品分页
export function prizePage(data) {
  return request({
    url: '/manage/draw/prizePage',
    method: 'post',
    data
  })
}
// 大转盘抽奖-奖品管理
export function prizeSave(data) {
  return request({
    url: '/manage/draw/savePrize',
    method: 'post',
    data
  })
}
// 大转盘抽奖-奖品移除
export function prizeRemove(data) {
  return request({
    url: '/manage/draw/removePrize',
    method: 'post',
    data
  })
}
// 大转盘抽奖-抽奖规则
export function luckDrawRuleQuery(data) {
  return request({
    url: '/manage/draw/activityPage',
    method: 'post',
    data
  })
}
// 大转盘抽奖-抽奖规则设置
export function luckDrawRuleSave(data) {
  return request({
    url: '/manage/draw/saveActivity',
    method: 'post',
    data
  })
}
// 大转盘抽奖-结束活动
export function activityEnd(data) {
  return request({
    url: '/manage/draw/activityFinish',
    method: 'post',
    data
  })
}
// 大转盘抽奖-抽奖活动详情
export function activityInfo(data) {
  return request({
    url: '/manage/draw/activityInfo',
    method: 'post',
    data
  })
}
// ============== 考证菜单管理 =================
// 列表
export function kzQueryList(data) {
  return request({
    url: '/manage/sys/kz/queryList',
    method: 'post',
    data
  })
}
// 新增/编辑
export function kzSaveObject(data) {
  return request({
    url: '/manage/sys/kz/saveObject',
    method: 'post',
    data
  })
}
// 删除
export function kzRemoveObject(data) {
  return request({
    url: '/manage/sys/kz/removeObject',
    method: 'post',
    data
  })
}
// 小程序绑定角色
export function bindAptitude(data) {
  return request({
    url: `/manage/dyQualification/bindAptitude/${data}`,
    method: 'post',
  })
}
// 角色授权小程序
export function empowerApplet(data) {
  return request({
    url: `/manage/dyQualification/empowerApplet`,
    method: 'post',
    data
  })
}
// 查看类目
export function queryCategory(data) {
  return request({
    url: `/manage/dyQualification/queryCategory/${data}`,
    method: 'post',
  })
}
// 更新实体信息
export function updateObject(data) {
  return request({
    url: `/manage/dyQualification/editObject/${data}`,
    method: 'post',
  })
}
// 抖音资质 更新审核详情
export function queryTiktokCategory(data) {
  return request({
    url: `/manage/dyQualification/queryTiktokCategory/${data}`,
    method: 'post',
  })
}
// 用户协议-条件查询
export function agreementListQuery(data) {
  return request({
    url: `manage/agreement/listQuery`,
    method: 'post',
    data
  })
}
// 用户协议-保存
export function agreementSaveObject(data) {
  return request({
    url: `manage/agreement/saveObject`,
    method: 'post',
    data
  })
}
