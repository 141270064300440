var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EmployeeInformation" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("el-tree", {
            attrs: {
              "node-key": "id",
              "expand-on-click-node": false,
              "default-expanded-keys": _vm.keys,
              data: _vm.DeptTree,
              props: _vm.DeptTreeProps
            },
            on: { "node-click": _vm.DeptTreeNodeClick }
          })
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "head" },
          [
            _c(
              "list-query",
              {
                attrs: { "show-filter": false, "show-other": false },
                on: {
                  search: _vm.tableSearch,
                  add: function($event) {
                    _vm.Infotitle = "新增员工"
                    _vm.operationorg = true
                    _vm.InfotableClear()
                  }
                }
              },
              [
                _c(
                  "template",
                  { slot: "default" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "info-form",
                        attrs: { inline: "", "auto-complete": "on" }
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入员工名称" },
                              model: {
                                value: _vm.requestParams.mixedTitle,
                                callback: function($$v) {
                                  _vm.$set(_vm.requestParams, "mixedTitle", $$v)
                                },
                                expression: "requestParams.mixedTitle"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "table-components",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                attrs: {
                  "show-index": true,
                  columns: _vm.columns,
                  data: _vm.tableData,
                  "page-size": _vm.requestParams.pageSize,
                  "page-num": _vm.requestParams.pageNum,
                  total: _vm.total
                },
                on: { "get-list": _vm.handleCurrentChange }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    slot: "operate",
                    fixed: "right",
                    label: "操作",
                    width: "250"
                  },
                  slot: "operate",
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.$store.state.common.hasPermission.includes("编辑")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.operationorg = true
                                      _vm.editUser(scope.row)
                                      _vm.Infotitle = "编辑员工"
                                    }
                                  }
                                },
                                [_vm._v("编辑 ")]
                              )
                            : _vm._e(),
                          _vm.$store.state.common.hasPermission.includes("删除")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDlelte(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-dropdown",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "table-btns",
                                  attrs: { size: "mini", type: "warning" }
                                },
                                [
                                  _vm._v(" 更多"),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right"
                                  })
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _vm.$store.state.common.hasPermission.includes(
                                    "分配权限"
                                  )
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.PersonRole(scope.row)
                                              _vm.personrole = true
                                            }
                                          }
                                        },
                                        [_vm._v("分配权限")]
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.common.hasPermission.includes(
                                    "工作交接"
                                  )
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { type: "primary" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.handover = true
                                              _vm.userId = scope.row.id
                                            }
                                          }
                                        },
                                        [_vm._v("工作交接")]
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.common.hasPermission.includes(
                                    "重置密码"
                                  )
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { type: "primary" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.Resetpwd = true
                                              _vm.userId = scope.row.id
                                            }
                                          }
                                        },
                                        [_vm._v("重置密码")]
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.common.hasPermission.includes(
                                    "课程顾问"
                                  )
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row.identityFlag ==
                                                  null ||
                                                scope.row.identityFlag.indexOf(
                                                  "1"
                                                ) == -1,
                                              expression:
                                                "\n                scope.row.identityFlag == null ||\n                scope.row.identityFlag.indexOf('1') == -1\n              "
                                            }
                                          ],
                                          attrs: { type: "primary" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.operationstudent = true
                                              _vm.userId = scope.row.id
                                            }
                                          }
                                        },
                                        [_vm._v("设置课程顾问")]
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.common.hasPermission.includes(
                                    "课程顾问"
                                  )
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row.identityFlag &&
                                                scope.row.identityFlag.indexOf(
                                                  "1"
                                                ) !== -1,
                                              expression:
                                                "\n                scope.row.identityFlag &&\n                scope.row.identityFlag.indexOf('1') !== -1\n              "
                                            }
                                          ],
                                          attrs: { type: "primary" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.removeteacher = true
                                              _vm.userId = scope.row.id
                                            }
                                          }
                                        },
                                        [_vm._v("取消课程顾问")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "删除员工", visible: _vm.removeuser, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.removeuser = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要删除该员工吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.removeuser = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.removeUser()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "分配权限", visible: _vm.personrole, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.personrole = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { staticStyle: { overflow: "hidden" } },
            [
              _c(
                "el-form-item",
                { staticClass: "personrole", attrs: { label: "请选择权限" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.roleIds,
                        callback: function($$v) {
                          _vm.roleIds = $$v
                        },
                        expression: "roleIds"
                      }
                    },
                    _vm._l(_vm.PersonRoleList, function(item) {
                      return _c("el-option", {
                        attrs: { label: item.roleName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.personrole = false
                      _vm.roleIds = ""
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.addPersonRole()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "工作交接", visible: _vm.handover, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.handover = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { staticStyle: { overflow: "hidden" } },
            [
              _c(
                "el-form-item",
                { staticClass: "hanDover", attrs: { label: "请选择接收员工" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.hanDoverId,
                        callback: function($$v) {
                          _vm.hanDoverId = $$v
                        },
                        expression: "hanDoverId"
                      }
                    },
                    _vm._l(_vm.hanDoverList, function(item) {
                      return _c("el-option", {
                        attrs: { label: item.personRealName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.handover = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.Handover()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.Infotitle,
            visible: _vm.operationorg,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.operationorg = $event
            },
            close: function($event) {
              _vm.$refs.addForm.clearValidate()
              _vm.selecttkey = false
            },
            open: function($event) {
              _vm.selecttkey = true
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "addForm",
                  staticStyle: {
                    overflow: "hidden",
                    "padding-left": "30px",
                    display: "flex",
                    "flex-wrap": "wrap"
                  },
                  attrs: { model: _vm.addForm, rules: _vm.addFormrules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工姓名", prop: "personRealName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.personRealName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "personRealName", $$v)
                          },
                          expression: "addForm.personRealName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "personMobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.addForm.personMobile,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "personMobile", $$v)
                          },
                          expression: "addForm.personMobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在部门", prop: "personDeptId" } },
                    [
                      _vm.selecttkey
                        ? _c("el-cascader", {
                            ref: "cascaderHandle",
                            attrs: {
                              filterable: "",
                              props: {
                                checkStrictly: true,
                                multiple: true,
                                children: "childList",
                                label: "orgName",
                                value: "id"
                              },
                              options: _vm.DeptTree,
                              "show-all-levels": false
                            },
                            on: { change: _vm.MenucascaderSelectChange },
                            model: {
                              value: _vm.addForm.deptArryStr,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "deptArryStr", $$v)
                              },
                              expression: "addForm.deptArryStr"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前岗位", prop: "postId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.postChange },
                          model: {
                            value: _vm.addForm.postId,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "postId", $$v)
                            },
                            expression: "addForm.postId"
                          }
                        },
                        _vm._l(_vm.PostList, function(item) {
                          return _c("el-option", {
                            attrs: { label: item.postName, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级负责人", prop: "parentId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.parentId,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "parentId", $$v)
                            },
                            expression: "addForm.parentId"
                          }
                        },
                        _vm._l(_vm.parentList, function(item) {
                          return _c("el-option", {
                            attrs: {
                              label: item.personRealName,
                              value: item.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.operationorg = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.doaddUser()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "重置密码", visible: _vm.Resetpwd, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.Resetpwd = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要重置密码吗？")]),
          _c("div", [_vm._v("重置后的新密码为：qc+手机号后6位")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.Resetpwd = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.ResetPWD()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置课程顾问",
            visible: _vm.operationstudent,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.operationstudent = $event
            },
            close: function($event) {
              return _vm.$refs.CourseForm.clearValidate()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "CourseForm",
                  staticClass: "addForm",
                  staticStyle: { overflow: "hidden", "padding-left": "30px" },
                  attrs: { model: _vm.CourseForm, rules: _vm.CourseFormrules }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称", prop: "personName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.CourseForm.personName,
                          callback: function($$v) {
                            _vm.$set(_vm.CourseForm, "personName", $$v)
                          },
                          expression: "CourseForm.personName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "二维码", prop: "imageUrl" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "avatar-uploader",
                          staticStyle: { "text-align": "center" },
                          attrs: {
                            action: _vm.imgURL,
                            accept: ".png,.jpg,.jpeg",
                            "show-file-list": false,
                            "file-list": _vm.fileList,
                            "on-success": _vm.uploadSuccess,
                            "on-change": _vm.localPathGet,
                            "auto-upload": false,
                            "before-upload": _vm.beforeUpload
                          }
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                staticStyle: {
                                  "max-width": "180px",
                                  "max-height": "180px"
                                },
                                attrs: { src: _vm.imageUrl }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "margin-top": "-10px",
                            "font-size": "12px"
                          }
                        },
                        [_vm._v(" 图片尺寸大小：180*180 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.operationstudent = false
                      _vm.clearCourseForm()
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.doaddTeacher()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "取消课程顾问",
            visible: _vm.removeteacher,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.removeteacher = $event
            }
          }
        },
        [
          _c("div", [_vm._v("确定要取消该课程顾问吗？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.removeteacher = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.removeTeacher()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }