import request from "@/utils/request";

//账号属性保存
export function saveAttr(data) {
  return request({
    url: '/manage/assets/status/saveAttr',
    method: 'post',
    data
  })
}

//账号属性删除
export function deleteAttr(data) {
  return request({
    url: '/manage/assets/status/deleteAttr',
    method: 'post',
    data
  })
}

//账号属性分页
export function attrPage(data) {
  return request({
    url: '/manage/assets/status/attrPage',
    method: 'post',
    data
  })
}

//异常状态保存
export function saveExceptional(data) {
  return request({
    url: '/manage/assets/status/saveExceptional',
    method: 'post',
    data
  })
}

//异常状态删除
export function deleteExceptional(data) {
  return request({
    url: '/manage/assets/status/deleteExceptional',
    method: 'post',
    data
  })
}

//异常状态分页
export function ExceptionalPage(data) {
  return request({
    url: '/manage/assets/status/exceptionalPage',
    method: 'post',
    data
  })
}
