var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pages" },
      [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("query", {
              attrs: { allColumns: _vm.columns },
              on: { search: _vm.searchAdd }
            }),
            _vm.$store.state.common.hasPermission.includes("导出")
              ? _c("export-excel", {
                  attrs: {
                    requestParams: _vm.exportQuery,
                    action: "/manage/wechat/data/totalStatistics",
                    columns: _vm.columns,
                    filename: "数据统计-总计统计"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "center-table",
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { textAlign: "center" },
              data: _vm.tableData,
              border: "",
              "cell-style": { textAlign: "center" },
              "show-summary": ""
            }
          },
          _vm._l(_vm.columns, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { prop: item.prop, label: item.label }
            })
          }),
          1
        ),
        _vm.pageSize
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", margin: "10px" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": _vm.pageSize,
                    "current-page": _vm.pageNum,
                    total: _vm.total
                  },
                  on: { "current-change": _vm.handleCurrentChange }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }