import request from '@/utils/request'
export function login(data) {  //登录
  return request({
    url: '/manage/login/managerLogin',
    method: 'post',
    data
  })
}
export function editPWD(data) {  //修改密码
  return request({
    url: '/manage/login/editPWD',
    method: 'post',
    data
  })
}

export function getMenuList(data) { //获取菜单
  return request({
    url: '/manage/assets/menu/findTmenuList',
    method: 'post',
    data
  })
}
export function getInfo() { //获取用户信息
  return request({
    url: '/manage/center/personInfo',
    method: 'post',
  })
}
export function editPerson(data) { //修改用户信息
  return request({
    url: '/manage/center/editPerson',
    method: 'post',
    data
  })
}
export function ResetPWD(data) { //重置密码
  return request({
    url: '/manage/login/ResetPWD',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/manage/login/logOut',
    method: 'post'
  })
}

export function findRoleMenuButton(data) { //查询页面按钮权限
  return request({
    url: '/manage/assets/menu/findRoleMenuButton',
    method: 'post',
    data
  })
}
