var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.item.slotName &&
      _vm.item.prop != "index" &&
      _vm.item.type != "img" &&
      _vm.item.type != "qrCode"
        ? _c("el-table-column", {
            key: _vm.index,
            attrs: {
              label: _vm.item.label,
              prop: _vm.item.prop,
              width: _vm.item.width ? _vm.item.width : "200",
              fixed: _vm.item.fixed,
              formatter: _vm.item.formatter,
              sortable: _vm.item.sortable ? _vm.item.sortable : false,
              "show-overflow-tooltip": _vm.item.showOverflowTooltip
                ? _vm.item.showOverflowTooltip
                : true
            }
          })
        : _vm._e(),
      _vm.item.type == "img"
        ? _c("el-table-column", {
            key: _vm.index,
            attrs: {
              label: _vm.item.label,
              prop: _vm.item.prop,
              width: _vm.item.width ? _vm.item.width : 100,
              fixed: _vm.item.fixed
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          scope.row[_vm.item.prop]
                            ? _c("el-image", {
                                staticStyle: {
                                  "max-width": "100px",
                                  height: "40px"
                                },
                                attrs: {
                                  src: scope.row[_vm.item.prop],
                                  fit: "contain",
                                  "preview-src-list": [scope.row[_vm.item.prop]]
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1652448171
            )
          })
        : _vm._e(),
      _vm.item.type == "qrCode"
        ? _c("el-table-column", {
            key: _vm.index,
            attrs: {
              label: _vm.item.label,
              prop: _vm.item.prop,
              width: _vm.item.width ? _vm.item.width : 100,
              fixed: _vm.item.fixed
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row[_vm.item.prop]
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "qr-container",
                                  on: { click: _vm.toggleQrZoom }
                                },
                                [
                                  _vm.item.url
                                    ? _c(
                                        "div",
                                        [
                                          _c("vue-qr", {
                                            staticStyle: {
                                              display: "inline-block"
                                            },
                                            attrs: {
                                              text:
                                                _vm.item.url +
                                                scope.row[_vm.item.prop],
                                              size: 40
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c("vue-qr", {
                                            staticStyle: {
                                              display: "inline-block"
                                            },
                                            attrs: {
                                              text: scope.row[_vm.item.prop],
                                              size: 40
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]
                              ),
                              _vm.isQrZoomed
                                ? _c(
                                    "div",
                                    { staticClass: "zoomed-qr-container" },
                                    [
                                      _c("div", [
                                        _c("i", {
                                          staticClass: "el-icon-circle-close",
                                          on: { click: _vm.toggleQrZoom }
                                        })
                                      ]),
                                      _vm.item.url
                                        ? _c(
                                            "div",
                                            [
                                              _c("vue-qr", {
                                                staticStyle: {
                                                  display: "inline-block"
                                                },
                                                attrs: {
                                                  text:
                                                    _vm.item.url +
                                                    scope.row[_vm.item.prop],
                                                  size: 250
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("vue-qr", {
                                                staticStyle: {
                                                  display: "inline-block"
                                                },
                                                attrs: {
                                                  text:
                                                    scope.row[_vm.item.prop],
                                                  size: 250
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              3866985593
            )
          })
        : _vm._e(),
      _vm.item.slotName ? _vm._t(_vm.item.slotName) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }