import { login, logout, getMenuList, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'
import { resetRouter } from '@/router'
import Cookies from 'js-cookie'


const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    menus: '',
    info: {},
    role: '',
    socket: '',
    sockettime: '',
    messagetime: '',

  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
  SET_SOCKET: (state, socket) => {
    state.socket = socket
  },
  SET_SOCKETTIME: (state, sockettime) => {
    state.sockettime = sockettime
  },
  SET_MESSAGETIME: (state, messagetime) => {
    state.messagetime = messagetime
  },


}
const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: username.trim(), password: password }).then(response => {
        if (response.status !== 200) {
          reject(response.message)
          return
        }
        const { data } = response
        commit('SET_TOKEN', data.tokenValue)
        commit('SET_NAME', data.userInfo.personName)
        commit('SET_AVATAR', data.userInfo.personPhoto)
        commit('SET_INFO', data.userInfo)

        setToken(data.tokenValue)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  //获取菜单
  getMenuList({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMenuList(data).then(response => {

        const { data } = response
        if (!data) {
          Message.error(response.message)
          removeToken()
          resetRouter()
          return
        }
        commit('SET_MENUS', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户信息
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response
        if (!data) {
          return reject('Verification failed, please Login again.')
        }
        commit('SET_NAME', data.personName)
        commit('SET_AVATAR', data.personPhoto)
        commit('SET_INFO', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

