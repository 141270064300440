import request from '@/utils/request'

// 视频库查询
export function videoLibfindPage(data) {
  return request({
    url: '/manage/course/videoLib/findPage',
    method: 'post',
    data
  })
}
// 视频库删除
export function videoLibDelVideo(data) {
  return request({
    url: '/manage/course/videoLib/delVideo',
    method: 'post',
    data
  })
}
// 视频库编辑
export function videoLibUpdateVideo(data) {
  return request({
    url: '/manage/course/videoLib/updateVideo',
    method: 'post',
    data
  })
}
// 视频库添加
export function videoLibAddVideo(data) {
  return request({
    url: '/manage/course/videoLib/addVideo',
    method: 'post',
    data
  })
}
// 课程上传抖音
export function uploadTiktok(data) {
  return request({
    url: '/manage/course/uploadTiktok',
    method: 'post',
    data
  })
}
// 赠品类型查询
export function queryGiftType(data) {
  return request({
    url: '/manage/course/gift/queryGiftType',
    method: 'post',
    data
  })
}
// 赠品信息添加
export function giftSaveObject(data) {
  return request({
    url: '/manage/course/gift/saveObject',
    method: 'post',
    data
  })
}
// 赠品信息编辑
export function giftEditObject(data) {
  return request({
    url: '/manage/course/gift/editObject',
    method: 'post',
    data
  })
}
// 赠品信息查询分页
export function giftPageQuery(data) {
  return request({
    url: '/manage/course/gift/pageQuery',
    method: 'post',
    data
  })
}
// 赠品信息移除
export function giftRemoveObject(data) {
  return request({
    url: '/manage/course/gift/removeObject',
    method: 'post',
    data
  })
}
// 开课平台添加
export function platformSaveObject(data) {
  return request({
    url: '/manage/course/platform/saveObject',
    method: 'post',
    data
  })
}
// 开课平台分页查询
export function platformPageQuery(data) {
  return request({
    url: '/manage/course/platform/pageQuery',
    method: 'post',
    data
  })
}
// 开课平台编辑
export function platformEditObject(data) {
  return request({
    url: '/manage/course/platform/editObject',
    method: 'post',
    data
  })
}
// 开课平台移除
export function platformRemoveObject(data) {
  return request({
    url: '/manage/course/platform/removeObject',
    method: 'post',
    data
  })
}
//视频点播临时令牌获取
export function videoAliSTS(data) {
  return request({
    url: '/manage/course/videoLib/aliSts/' + data,
    method: 'post',

  })
}
// 课程查询
export function queryCourseList(data) {
  return request({
    url: '/manage/course/queryList',
    method: 'post',
    data
  })
}
// 课程包查询
export function queryPackageList(data) {
  return request({
    url: '/manage/course/queryPackageList',
    method: 'post',
    data
  })
}
// 赠品详情查询
export function queryGiftObjectInfo(data) {
  return request({
    url: '/manage/course/gift/queryObjectInfo',
    method: 'post',
    data
  })
}
// 录播课程分页查询
export function coursePageQuery(data) {
  return request({
    url: '/manage/course/pageQuery',
    method: 'post',
    data
  })
}
// 录播课程详情查询
export function getCourseDetails(data) {
  return request({
    url: '/manage/course/queryCourse/' + data,
    method: 'post',
  })
}
// 课程标签查询
export function queryLabelList(data) {
  return request({
    url: '/manage/course/queryLabelList',
    method: 'post',
    data
  })
}
// 课程包详情
export function queryPackageInfo(data) {
  return request({
    url: '/manage/course/queryPackageInfo',
    method: 'post',
    data
  })
}
// 课程添加
export function saveCourse(data) {
  return request({
    url: '/manage/course/saveCourse',
    method: 'post',
    data
  })
}
// 课程-编辑
export function editCourse(data) {
  return request({
    url: '/manage/course/editCourse',
    method: 'post',
    data
  })
}
// 课程-章节详情
export function chapterDetails(data) {
  return request({
    url: '/manage/course/section/chapterDetails',
    method: 'post',
    data
  })
}
// 课程章-新增
export function addCourseChapter(data) {
  return request({
    url: '/manage/course/section/addCourseChapter',
    method: 'post',
    data
  })
}
// 课程章-修改
export function updateCourseChapter(data) {
  return request({
    url: '/manage/course/section/updateCourseChapter',
    method: 'post',
    data
  })
}
// 课程章-删除
export function chapterDelete(data) {
  return request({
    url: '/manage/course/section/chapterDelete',
    method: 'post',
    data
  })
}
// 课程章-移动
export function chapterMove(data) {
  return request({
    url: '/manage/course/section/chapterMove',
    method: 'post',
    data
  })
}
// 课程节-新增
export function addSection(data) {
  return request({
    url: '/manage/course/section/addSection',
    method: 'post',
    data
  })
}
// 课程节-编辑
export function updateSection(data) {
  return request({
    url: '/manage/course/section/updateSection',
    method: 'post',
    data
  })
}
// 课程节-删除
export function deleteSection(data) {
  return request({
    url: '/manage/course/deleteSection',
    method: 'post',
    data
  })
}
// 课程节-上升、下移
export function upDownSection(data) {
  return request({
    url: '/manage/course/upDownSection',
    method: 'post',
    data
  })
}
// 课程|课程包  上下架
export function putOrOffShelf(data) {
  return request({
    url: '/manage/course/putOrOffShelf',
    method: 'post',
    data
  })
}
// 课程包-新增
export function savePackageObject(data) {
  return request({
    url: '/manage/course/savePackageObject',
    method: 'post',
    data
  })
}
// 课程包-编辑
export function editPackage(data) {
  return request({
    url: '/manage/course/editPackage',
    method: 'post',
    data
  })
}
// 课程包-阶段添加
export function savePackageStage(data) {
  return request({
    url: '/manage/course/savePackageStage',
    method: 'post',
    data
  })
}
// 课程包-阶段编辑
export function modifiedPackageStage(data) {
  return request({
    url: '/manage/course/modifiedPackageStage',
    method: 'post',
    data
  })
}

// 课程包-单个阶段移除
export function deleteStage(data) {
  return request({
    url: '/manage/course/deleteStage',
    method: 'post',
    data
  })
}
// 课程包-阶段上升下移
export function upDownStage(data) {
  return request({
    url: '/manage/course/upDownStage',
    method: 'post',
    data
  })
}
// 课程包-单个课程移除
export function deleteStageCourse(data) {
  return request({
    url: '/manage/course/deleteStageCourse',
    method: 'post',
    data
  })
}
// 课程包-课程上升下移
export function upDownCourse(data) {
  return request({
    url: '/manage/course/upDownCourse',
    method: 'post',
    data
  })
}
// 课程包-阶段课程查询
export function queryStageList(data) {
  return request({
    url: '/manage/course/queryStageList',
    method: 'post',
    data
  })
}
// 课程包-阶段课程添加
export function saveStageCourse(data) {
  return request({
    url: '/manage/course/saveStageCourse',
    method: 'post',
    data
  })
}
// 课程包-阶段课程编辑
export function modifiedStageCourse(data) {
  return request({
    url: '/manage/course/modifiedStageCourse',
    method: 'post',
    data
  })
}
// 课程包-阶段中的课程批量添加
export function saveBatchStageCourse(data) {
  return request({
    url: '/manage/course/saveBatchStageCourse',
    method: 'post',
    data
  })
}
// 录播课程列表-seo设置
export function saveSeoInfo(data) {
  return request({
    url: '/manage/course/saveSeoInfo',
    method: 'post',
    data
  })
}
// 小节默认讲师查询
export function querySectionTeacher(data) {
  return request({
    url: '/manage/course/section/querySectionTeacher',
    method: 'post',
    data
  })
}
// 课程移除
export function removeCourse(data) {
  return request({
    url: '/manage/course/removeCourse',
    method: 'post',
    data
  })
}
// 课程包移除
export function removePackageInfo(data) {
  return request({
    url: '/manage/course/removePackageInfo',
    method: 'post',
    data
  })
}
// 课程|包上移 下移
export function upDownCourseOrPageSort(data) {
  return request({
    url: '/manage/course/upDownCourseOrPageSort',
    method: 'post',
    data
  })
}
// 赠品启用禁用
export function putOrOffGift(data) {
  return request({
    url: '/manage/course/gift/putOrOffGift',
    method: 'post',
    data
  })
}
// 视频资源批量修改
export function batchModifiedMajor(data) {
  return request({
    url: '/manage/course/videoLib/batchModifiedMajor',
    method: 'post',
    data
  })
}
// 赠品启用禁用
export function getPlayUrl(data) {
  return request({
    url: '/manage/course/videoLib/getPlayUrl/' + data,
    method: 'post'
  })
}
// 资源库数据上传抖音
export function LibuploadTiktok(data) {
  return request({
    url: '/manage/course/videoLib/uploadTiktok',
    method: 'post',
    data
  })
}
// 录播课程导出
export function courseExport(data) {
  return request({
    url: '/manage/course/courseExport',
    method: 'post',
    data
  })
}
// 报名数据导出
export function exportCourseOrder(data) {
  return request({
    url: '/manage/course/exportCourseOrder',
    method: 'post',
    data
  })
}
// 抖音审核状态列表
export function findStatusList(data) {
  return request({
    url: '/manage/tiktokCourse/findStatusList',
    method: 'post',
    data
  })
}
// 抖音课程保存
export function saveTiktokCourse(data) {
  return request({
    url: '/manage/tiktokCourse/saveObject',
    method: 'post',
    data
  })
}
// 抖音课程上传到抖音
export function uploadTikTok(data) {
  return request({
    url: '/manage/tiktokCourse/uploadTikTok',
    method: 'post',
    data
  })
}
// 抖音课程移除
export function removeObject(data) {
  return request({
    url: '/manage/tiktokCourse/removeObject',
    method: 'post',
    data
  })
}
// 抖音课程分页
export function tiktokPageQuery(data) {
  return request({
    url: '/manage/tiktokCourse/pageQuery',
    method: 'post',
    data
  })
}
// 抖音类型 行业类目
export function queryCourseClass(data) {
  return request({
    url: '/manage/tiktokCourse/queryCourseClass',
    method: 'post',
    data
  })
}
//资质资源添加
export function aptitudeAdd(data) {
  return request({
    url: '/manage/course/videoLib/aptitudeAdd',
    method: 'post',
    data
  })
}
//资质资源编辑
export function aptitudeEdit(data) {
  return request({
    url: '/manage/course/videoLib/aptitudeEdit',
    method: 'post',
    data
  })
}

