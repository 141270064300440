var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      _c("audio", {
        attrs: {
          src:
            "http://qcjy-portal.oss-cn-beijing.aliyuncs.com/questionFiles/1652348774123lH0ArQ.mp3",
          id: "audio"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }